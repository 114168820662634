import React from "react";
import { Image, Pressable, StyleSheet, ActivityIndicator } from "react-native";
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
} from "react-native-reanimated";
import { colors } from "@src/theme";
import { AuthContext } from "@src/AuthProvider";

const AnimatedButton = Animated.createAnimatedComponent(Pressable);

const ImageButton04 = (props: any) => {
  const animated = useSharedValue(1);
  const { handleClick } = React.useContext(AuthContext);
  const { style, source, onPress, disabled, scaleSize, isLoading } = props;

  const onPressIn = () => {
    animated.value = withTiming(scaleSize || 0.9, { duration: 100 });
  };

  const onPressOut = () => {
    animated.value = withTiming(1, { duration: 100 });
  };

  const onClick = () => {
    onPress();
    handleClick();
  };

  const animateStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          scale: animated.value,
        },
      ],
    };
  }, []);

  return (
    <AnimatedButton
      onPress={onClick}
      disabled={disabled}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      style={[styles.buttonStyle, style, animateStyle]}
    >
      {isLoading && (
        <ActivityIndicator
          style={styles.spinner}
          color={colors.white}
          size="large"
          animating
        />
      )}
      <Image
        source={source}
        resizeMode="stretch"
        style={styles.imageButtonStyle}
      />
    </AnimatedButton>
  );
};

const styles = StyleSheet.create({
  buttonStyle: {
    width: "40%",
    height: 70,
    alignItems: "center",
    justifyContent: "center",
  },
  imageButtonStyle: {
    width: "100%",
    height: "100%",
  },
  spinner: { position: "absolute", zIndex: 1 },
});

export default ImageButton04;
