import React from "react";
import { ImageButton } from "components/button";
import { View, Text } from "react-native";
import useStyles from "./styles.css";
import { images } from "@src/theme";
import Facebook from "./Facebook";
import Google from "./Google";

interface FooterProps {
  onPress?: () => void;
  baseWidth: number;
}

const Footer = ({ onPress, baseWidth }: FooterProps) => {
  const styles = useStyles();

  return (
    <View style={styles.loginWrapper}>
      <ImageButton
        onPress={onPress}
        source={images.btn_login}
        style={[styles.btnLogin]}
      />
      <View style={[styles.separator]}>
        <View style={styles.divider} />
        <Text style={[styles.txtOrstyle, { fontSize: baseWidth * 0.01}]}>Or</Text>
        <View style={styles.divider} />
      </View>
      <View style={styles.v_footer}>
        <Google />
        <Facebook />
      </View>
    </View>
  );
};

export default Footer;
