import React from "react";
import { Button01 } from "components/button";
import { PaymentContext } from "./provider";
import { StyleSheet } from "react-native";
import { colors } from "@src/theme";

const ApcopayButton = () => {
  const { onApcopayCreditCard } = React.useContext(PaymentContext);

  return (
    <Button01
      onPress={onApcopayCreditCard}
      style={[styles.btn_style]} 
      label="Apcopay Credit Card" 
      labelStyle={styles.button_label} />
  );
};

const styles = StyleSheet.create({
  btn_style: {
    width: "100%",
    height: 60,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.white
  },
  button_label: {
    marginLeft: 5,
    fontSize: 14,
    lineHeight: 18,
    color: colors.black,
    fontFamily: "Roboto_Bold",
  },
});

export default ApcopayButton;