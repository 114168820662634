import React, { useState, useEffect } from "react";
import { useNavigation } from "@react-navigation/native";
import { useForgotService } from "@src/ducks/hooks";
import { TextInput01 } from "components/textinput";
import { View, Image, Text } from "react-native";
import { ImageButton } from "components/button";
import useStyles from "./styles.css";
import { images } from "@src/theme";

const EnterEmail = () => {
  const styles = useStyles();
  const navigation = useNavigation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const {onForgot, isLoading}= useForgotService();

  const onSubmit = () => {
    if (!email) {
      setError("Required.");
    } else {
      onForgot(email);
    }
  };

  useEffect(() => {
    setError("");
  }, [email]);

  return (
    <View style={[styles.bodyStyle]}>
      <Image
        source={images.bg_transparent}
        style={styles.imageBorderStyle}
        resizeMode="stretch"
      />
      <ImageButton
        onPress={() => navigation.goBack()}
        style={styles.btnClose}
        source={images.btn_close}
      />
      <View style={styles.viewEmail}>
        <View style={[styles.viewForgotStyle]}>
          <Image
            resizeMode="contain"
            source={images.forgot}
            style={styles.forgotTextStyle}
          />
        </View>
        <Text style={styles.txtEmailNote}>Enter your email address</Text>
        <TextInput01
          value={email}
          error={error}
          placeholder="Email"
          secureTextEntry={false}
          onChangeText={setEmail}
          disableFullscreenUI={true}
          inputStyle={styles.textAlign}
          containerStyle={styles.inputEmailStyle}
        />
        <ImageButton
          onPress={onSubmit}
          isLoad={isLoading}
          style={styles.btnSubmit}
          source={images.btnSubmit}
        />
      </View>
    </View>
  );
};

export default EnterEmail;
