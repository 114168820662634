import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { RootStackOptionsValue, RootStackParamList, TransitionModal } from "./NavigatorTypes";

// REDUCER
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectAuthLoggedIn } from "@src/ducks/slices/auth.slice";

// COMPONENT SCREENS
import Forgot from "@src/screens/auth/forgot";
import SignIn from "@src/screens/auth/login";
import SignInOption from "@src/screens/auth/main";
import ResetPassword from "@src/screens/auth/resetpassword";
import SignUp from "@src/screens/auth/signup";

import PaymentConfirm from "screens/buyshop/paymentconfirm";
import Disclaimer from "screens/disclaimer";
import EditName from "screens/editname";
import GameMode from "screens/gamemode";
import Inbox from "screens/inbox";
import MissionKey from "screens/mission/popupkey";
import Mission from "screens/mission/popvault";
import PaymentGateway from "screens/paymentgateway";
import BitpaceResult from "screens/paymentgateway/bitpace/resultpopup";
import ApcopayResult from "screens/popups/apcopay-result";
import TierLevel from "screens/tierlevel";
import EditProfile from "screens/updateprofile";

// LAZY COMPONENT
const HomeComponent = React.lazy(() => import("screens/home/main"));

// ANIMATION POP-UPS
import LazyLoadingPopup from "@src/screens/popups";
import SplashBg from "components/splash/splash-bg";

const RootStack = createStackNavigator<RootStackParamList>();

const RootStackOptions: RootStackOptionsValue = {
  headerShown: false,
  title: "GWz Fortune 8",
  cardStyle: { backgroundColor: "transparent" },
};

const AppRouting = () => {
  const isLoggedIn = useAppSelector(selectAuthLoggedIn);

  const screenComponents = React.useMemo(() => {
    if (isLoggedIn) {
      return (
        <>
          <RootStack.Screen name="Home">
            {() => (
              <React.Suspense fallback={<SplashBg />}>
                <HomeComponent />
              </React.Suspense>
            )}
          </RootStack.Screen>
          <RootStack.Screen name="GameMode" component={GameMode} />
          <RootStack.Screen name="EditName" component={EditName} options={TransitionModal} />
          <RootStack.Screen name="EditProfile" component={EditProfile} options={TransitionModal} />
          <RootStack.Screen name="Disclaimer" component={Disclaimer} options={TransitionModal} />
          <RootStack.Screen name="TierLevel" component={TierLevel} options={TransitionModal} />
          <RootStack.Screen name="Mission" component={Mission} options={TransitionModal} />
          <RootStack.Screen name="MissionKey" component={MissionKey} options={TransitionModal} />
          <RootStack.Screen name="PaymentGateway" component={PaymentGateway} options={TransitionModal} />
          <RootStack.Screen name="Inbox" component={Inbox} options={TransitionModal} />
          <RootStack.Screen name="PaymentConfirm" component={PaymentConfirm} options={TransitionModal} />
          <RootStack.Screen name="BitpaceResult" component={BitpaceResult} options={TransitionModal} />
          <RootStack.Screen name="Apcopay" component={ApcopayResult} options={TransitionModal} />

          <RootStack.Screen name="WelcomeBonus" component={LazyLoadingPopup} options={TransitionModal} />
          <RootStack.Screen name="DailyBonus" component={LazyLoadingPopup} options={TransitionModal} />
          <RootStack.Screen name="DragonRoller" component={LazyLoadingPopup} options={TransitionModal} />
          <RootStack.Screen name="HighRoller" component={LazyLoadingPopup} options={TransitionModal} />
          <RootStack.Screen name="HotDeals" component={LazyLoadingPopup} options={TransitionModal} />
          <RootStack.Screen name="TGIF" component={LazyLoadingPopup} options={TransitionModal} />
          <RootStack.Screen name="StarterPack" component={LazyLoadingPopup} options={TransitionModal} />
          <RootStack.Screen name="WeeklyBooster" component={LazyLoadingPopup} options={TransitionModal} />
          <RootStack.Screen name="Settings" component={LazyLoadingPopup} options={TransitionModal} />
          <RootStack.Screen name="BuyShop" component={LazyLoadingPopup} options={TransitionModal} />
          <RootStack.Screen name="JadeEggShow" component={LazyLoadingPopup} options={TransitionModal} />
          <RootStack.Screen name="HotDealsThumbnails" component={LazyLoadingPopup} options={TransitionModal} />
          <RootStack.Screen name="TierRewards" component={LazyLoadingPopup} options={TransitionModal} />
        </>
      );
    } else {
      return (
        <>
          <RootStack.Screen name="SignInOption" component={SignInOption} />
          <RootStack.Screen name="SignIn" component={SignIn} />
          <RootStack.Screen name="SignUp" component={SignUp} />
          <RootStack.Screen name="Forgot" component={Forgot} />
          <RootStack.Screen name="ResetPassword" component={ResetPassword} />
          <RootStack.Screen name="BitpaceResult" component={BitpaceResult} options={TransitionModal} />
          <RootStack.Screen name="Apcopay" component={ApcopayResult} options={TransitionModal} />
        </>
      );
    }
  }, [isLoggedIn]);

  return (
    <RootStack.Navigator screenOptions={RootStackOptions}>
      {screenComponents}
    </RootStack.Navigator>
  );
};

export default AppRouting;
