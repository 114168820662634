import React from "react";
import {
  View,
  Pressable,
  StyleSheet,
  Text,
  ActivityIndicator,
} from "react-native";
import { colors } from "@src/theme";

type ButtonProps = {
  onPress?: () => void;
  label?: string;
  isLoad?: boolean;
  color?: string;
  disabled?: boolean;
  style?: any | undefined;
  labelStyle?: any | undefined;
};

const Button = (props: ButtonProps) => {
  const { onPress, style, labelStyle, label, isLoad, color, disabled } = props;

  if (isLoad) {
    return (
      <View style={[styles.container, style]}>
        <ActivityIndicator
          size="small"
          animating={isLoad}
          color={color || "black"}
        />
      </View>
    );
  }

  return (
    <Pressable
      style={[styles.container, style]}
      onPress={onPress}
      disabled={disabled}
    >
      <Text style={[styles.labelStyle, labelStyle]}>{label}</Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 40,
    alignItems: "center",
    paddingHorizontal: 20,
    justifyContent: "center",
    backgroundColor: colors.white,
  },
  labelStyle: {
    fontFamily: "GoogleSans_Medium",
    fontSize: 14,
    color: colors.white,
  },
});

export default Button;
