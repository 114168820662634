import React, { useRef, useState, useEffect, useImperativeHandle } from "react";
import { StyleSheet, Animated } from "react-native";
import { colors } from "@src/theme";

const ProgressBar = React.forwardRef((_, ref) => {
  const [progress, setProgress] = useState(0);
  const animation = useRef(new Animated.Value(0));
  const finish = () => {
    setProgress(100);
  };

  useImperativeHandle(ref, () => ({
    finish,
  }));

  useEffect(() => {
    Animated.timing(animation.current, {
      toValue: progress,
      duration: 100,
      useNativeDriver: false,
    }).start();
  }, [progress]);

  useEffect(() => {
    const id = setInterval(() => {
      setProgress((seconds) => {
        if (seconds >= 99) {
          clearInterval(id);
        }

        if (seconds >= 90) {
          return seconds;
        }

        return seconds + 1;
      });
    }, 100);

    return () => clearInterval(id);
  }, []);

  const width = animation.current.interpolate({
    inputRange: [0, 50],
    outputRange: ["0%", "100%"],
    extrapolate: "clamp",
  });

  if (progress >= 100) {
    return null;
  }

  return <Animated.View style={[styles.container, { width }]} />;
});

ProgressBar.displayName = "ProgressBar";

const styles = StyleSheet.create({
  container: {
    height: "100%",
    borderRadius: 15,
    overflow: "hidden",
    backgroundColor: colors.progress,
  },
});

export default ProgressBar;
