import Request from "./Request";
import { API_BASE_URL, MERCHANT_URL, MERCHANT_CODE, MERCHANT_PASSWORD} from "@env";

// API CORE
export const CurrentApi = new Request(API_BASE_URL);
export const BitpaceMerchantApi = new Request(MERCHANT_URL);

export const setToken = (token: string) => {
  CurrentApi.setToken(token);
  BitpaceMerchantApi.setToken(token);
};

export const callGet = async (routes: string, token?: string) => {
  try {
    if (token) {
      CurrentApi.setToken(token);
    }

    const result = await CurrentApi.get(`${routes}`);

    return result;
  } catch (e) {
    throw e;
  }
};

export const callPost = async (
  routes: string,
  params: any,
  token?: string | undefined
) => {
  try {
    if (token) {
      CurrentApi.setToken(token);
    }

    const result = await CurrentApi.post(`${routes}`, params);

    return result;
  } catch (e) {
    throw e;
  }
};

export const callPatch = async (
  routes: string,
  params: any,
  token?: string | undefined
) => {
  try {
    if (token) {
      CurrentApi.setToken(token);
    }

    const result = await CurrentApi.patch(`${routes}`, params);

    return result;
  } catch (e) {
    throw e;
  }
};

export const callPut = async (
  routes: string,
  params: any,
  token?: string | undefined
) => {
  try {
    if (token) {
      CurrentApi.setToken(token);
    }

    const result = await CurrentApi.put(`${routes}`, params);

    return result;
  } catch (e) {
    throw e;
  }
};

export const get = async (url: any, payload: any) => {
  try {
    const response = await fetch(url, payload);

    if (response.ok === false) {
      throw await response.json();
    }

    return response.json().catch(() => {
      return { message: "Success" };
    });
  } catch (e) {
    throw e;
  }
};

export const merchantAuth = async () => {
  try {
    const response = await BitpaceMerchantApi.post("/auth/token", {
      merchant_code: MERCHANT_CODE,
      password: MERCHANT_PASSWORD,
    });

    return response.data.token;
  } catch (e: any) {
    throw { message: e.message, code: e.status || 404, ...e };
  }
};

export const merchantPost = async (
  routes: string,
  params: any,
  token?: string | undefined
) => {
  try {
    if (token) {
      BitpaceMerchantApi.setToken(token);
    }

    const result = await BitpaceMerchantApi.post(`${routes}`, { ...params });

    return result;
  } catch (e) {
    throw e;
  }
};
