import { useState, useCallback } from "react";
import {
  authActions,
  selectedAuthToken,
} from "@src/ducks/slices/auth.slice";
import { useAppDispatch, useAppSelector } from "@src/ducks/ducksHook";
import { CurrentApi } from "../request";

export const useFetchPatch = (initialData = null) => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState<boolean>(false);
  const accessToken = useAppSelector(selectedAuthToken);

  const runRequest = useCallback(
    async (routes: string, params: any) => {
      try {
        setLoading(true);
        CurrentApi.setToken(accessToken);
        const result = await CurrentApi.patch(`${routes}`, params);

        setData(result.data);
      } catch (err: any) {
        const message = err.message || err.error || "Something went wrong";
        setError({ message });

        if (message.includes("Unauthorized")) {
          dispatch(authActions.logout());
        }
      } finally {
        setLoading(false);
      }
    },
    [accessToken, dispatch]
  );

  return {
    loading,
    data,
    error,
    runRequest,
  } as any;
};
