import { useNavigation } from "@react-navigation/native";
import { useProductService } from "@src/ducks/hooks/useProductService";
import { Product } from "@src/ducks/types";
import { images } from "@src/theme";
import { ImageButton } from "components/button";
import React from "react";
import { Image, View } from "react-native";
import { PROMOTION_POPUPS } from "utils/constants";
import useMetrics from "utils/metrics";
import useStyles from "./styles.css";

const IMAGE_PATH = images.cheaptuesday;

const CheapTuesday = () => {
  const styles = useStyles();
  const { verticalScale } = useMetrics();
  const { getProductByName } = useProductService();
  const navigation = useNavigation<any>();

  const onPress = () => {
    const product: Product = getProductByName(PROMOTION_POPUPS.CHEAPY_TUESDAY);
    navigation.navigate("PaymentGateway", {
      imagePath: IMAGE_PATH,
      productId: product.name,
      isShop: true,
      amount: product.price,
      currency: "USD",
      silver: product.value,
      type: "cheaptuesday",
    });
  };

  return (
    <View style={[styles.v_body, { width: "98%" }]}>
      <View style={[styles.v_details, { alignItems: "center" }]}>
        <Image
          style={[styles.i_pack, { transform: [{ scale: 1 }] }]}
          source={IMAGE_PATH}
          resizeMode="stretch"
        />
        <ImageButton
          onPress={onPress}
          style={[
            styles.btn_buynow,
            { right: "18%", bottom: "15%" },
            {
              width: verticalScale(200),
              height: verticalScale(90),
            },
          ]}
          source={images.btn_buy_now}
        />
      </View>
    </View>
  );
};

export default CheapTuesday;
