import React from "react";
import { ImageButton02 } from "components/button";
import { verticalScale } from "utils/metrics";
import { View, Image } from "react-native";
import useStyles from "./styles.css";
import { images } from "@src/theme";
import Scale from "./Scale";

export default ({ item, onPress }: any) => {
  const styles = useStyles();

  return (
    <View
      style={[
        styles.v_item_container,
        {
          height: verticalScale(230),
          width: verticalScale(230),
          marginHorizontal: verticalScale(14),
        },
      ]}
    >
      <ImageButton02
        style={[
          styles.v_center,
          {
            height: verticalScale(220),
            width: verticalScale(220),
          },
        ]}
        scaleSize={0.96}
        onPress={() => onPress(item)}
      >
        <Image
          style={styles.bg_item}
          source={images.bg_item_low}
          resizeMode="stretch"
        />
        {(item.isBestValue || item.isMostPopular) && (
          <Scale style={styles.i_best_value}>
            <Image
              style={[
                styles.i_best_value,
                {
                  top: -verticalScale(25),
                  left: verticalScale(10),
                  width: verticalScale(110),
                  height: verticalScale(110),
                },
              ]}
              source={(item.isBestValue && images.best_value) 
                || (item.isMostPopular && images.most_popular)}
              resizeMode="contain"
            />
          </Scale>
        )}
        <View style={styles.v_top}>
          <Image
            style={[
              {
                width: verticalScale(140),
                height: verticalScale(140),
              },
              item.bonusGold && { right: "-3%" },
            ]}
            source={images[item.chestIcon]}
            resizeMode="contain"
          />
          {item.bonusGold && (
            <Image
              style={[
                styles.i_bonus_gold,
                {
                  top: verticalScale(40),
                  left: -verticalScale(30),
                  width: verticalScale(80),
                  height: verticalScale(80),
                },
              ]}
              source={images[item.bonusGold]}
              resizeMode="contain"
            />
          )}
        </View>
        <View style={styles.v_price}>
          <Image
            style={styles.bg_item}
            source={images[item.priceIcon]}
            resizeMode="contain"
          />
        </View>
      </ImageButton02>
    </View>
  );
};
