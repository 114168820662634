import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../ducksHook";
import {
  forgotActions, selectForgotLoad
} from "../slices/forgot.slice";
import { ResetPasswordInput } from "../types";

// Types

type ServiceOperators = {
  isLoading: boolean;
  onForgot: (email: string) => void;
  onResetPassword: (params: ResetPasswordInput) => void;
};

export const useForgotService = (): Readonly<ServiceOperators> => {
  const dispatch = useAppDispatch();

  return {
    isLoading: useAppSelector(selectForgotLoad),
    onForgot: useCallback(
      (params: string) => {
        dispatch(forgotActions.forgotRequest(params));
      },
      [dispatch]
    ),
    onResetPassword: useCallback(
      (params: ResetPasswordInput) => {
        dispatch(forgotActions.resetRequest(params));
      },
      [dispatch]
    ),
  };
};

export default useForgotService;
