// DUCKS pattern
import { createAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@src/ducks/store";
import { ErrorValue, GameMode, SwordPlayInit } from "../types";

interface InitialState {
  loading: boolean;
  gameURL: string;
  error: ErrorValue;

  swordplayData: any;
}

const initialState: InitialState = {
  loading: false,
  gameURL: "",
  error: { message: ""} as ErrorValue,
} as InitialState;

// Slice
export const gmodeSlice = createSlice({
  name: "gmode",
  initialState,
  reducers: {
    gmodeRequest: (state) => {
      state.loading = true;
      state.error = {} as ErrorValue;
    },
    gmodeSuccess: (state, action) => {
      state.gameURL = action.payload;
      state.error = {} as ErrorValue;
      state.loading = false;
    },
    gmodeFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    gameModeLeave: (state) => {
      state.gameURL = "";
    },

    topupSwordPlayRequest: (state) => {
      state.loading = true;
      state.error = {} as ErrorValue;
    },
    topupSwordPlaySuccess: (state, action) => {
      state.swordplayData = action.payload;
      state.error = {} as ErrorValue;
      state.loading = false;
    },
    topupSwordPlayFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

// Actions
export const gmodeActions = {
  gmodeRequest: createAction(
    `${gmodeSlice.name}/gmodeRequest`,
    (params: GameMode) => ({
      payload: params,
    })
  ),
  gmodeSuccess: gmodeSlice.actions.gmodeSuccess,
  gmodeFailure: gmodeSlice.actions.gmodeFailure,

  gameModeLeave: createAction(`${gmodeSlice.name}/gameModeLeave`),
  missionRequest: createAction(`${gmodeSlice.name}/missionRequest`),

  topupSwordPlayRequest: createAction(
    `${gmodeSlice.name}/topupSwordPlayRequest`,
    (params: SwordPlayInit) => ({
      payload: params,
    })
  ),
  topupSwordPlaySuccess: gmodeSlice.actions.topupSwordPlaySuccess,
  topupSwordPlayFailure: gmodeSlice.actions.topupSwordPlayFailure,
};

// Selectors
export const selectedGModeLoading = (state: RootState) => state.gmode.loading;
export const selectedGModeFailed = (state: RootState) => state.gmode.error;
export const selectedGModeURL = (state: RootState) => state.gmode.gameURL;
export const selectedSwordPlay = (state: RootState) => state.gmode.swordplayData;

// Reducer
export default gmodeSlice.reducer;
