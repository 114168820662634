import { images } from "@src/theme";

const TierLevel = [
  {
    id: 1,
    gem: "Sapphire",
    tier_icon: images.tier_sapphire,
  },
  {
    id: 2,
    gem: "Emerald",
    tier_icon: images.tier_emerald,
  },
  {
    id: 3,
    gem: "Ruby",
    tier_icon: images.tier_ruby,
  },
  {
    id: 4,
    gem: "Diamond",
    tier_icon: images.tier_diamond,
  },
];

export default TierLevel;
