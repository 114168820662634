import React from "react";
import { useNavigation } from "@react-navigation/native";
import { ImageButton } from "components/button";
import { View, Image } from "react-native";
import useMetrics from "utils/metrics";
import useStyles from "./styles.css";
import { images } from "@src/theme";

const Games = (props: any) => {
  const styles = useStyles();
  const { verticalScale } = useMetrics();
  const navigation = useNavigation<any>();

  const onPress = () => {
    navigation.navigate("GameMode", {
      ...props.details,
    });
  };

  return (
    <View style={[styles.v_body, { width: "100%" }]}>
      <View style={[styles.v_details, { alignItems: "center" }]}>
        <Image style={styles.i_text} source={props.text} resizeMode="contain" />
        <Image
          style={styles.i_avatar}
          source={props.avatar}
          resizeMode="stretch"
        />
        <Image
          style={[styles.i_games]}
          source={props.games}
          resizeMode="stretch"
        />
        <ImageButton
          onPress={onPress}
          style={[
            styles.btn_playnow,
            {
              width: verticalScale(180),
              height: verticalScale(80),
            },
          ]}
          source={images.btn_playnow}
        />
      </View>
    </View>
  );
};

export default Games;
