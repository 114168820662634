import React from "react";
import { useNavigation } from "@react-navigation/native";
import { View, Image, Text } from "react-native";
import { ImageButton } from "components/button";
import useStyles from "./styles.css";
import { images } from "@src/theme";

const Success = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();

  const onPress = () => {
    navigation.navigate("SignIn");
  };

  return (
    <View style={[styles.bodyStyle]}>
      <Image
        source={images.bg_transparent}
        style={styles.imageBorderStyle}
        resizeMode="stretch"
      />
      <View style={styles.v_center}>
        <View style={[styles.viewForgotStyle]}>
          <Image
            resizeMode="contain"
            source={images.success}
            style={styles.i_success}
          />
        </View>
        <View style={[styles.v_logo]}>
          <Image
            resizeMode="stretch"
            source={images.fortune_logo}
            style={styles.i_fortune_logo}
          />
        </View>
        <View style={[styles.viewEmail]}>
          <Text style={styles.txtEmailNote}>
            Your password has been updated.
          </Text>
          <View style={styles.height20} />
          <Text style={styles.txtEmailNote}>
            You may log in and start playing!
          </Text>
          <View style={styles.height20} />
        </View>
        <ImageButton
          onPress={onPress}
          style={styles.btnSubmit}
          source={images.btn_login}
        />
      </View>
    </View>
  );
};

export default Success;
