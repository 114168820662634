import React, { useRef, useEffect } from 'react';
import { Animated, Easing } from 'react-native';

const ShakeAnimated = ({ children, style, animate }: any) => {
  const animated = useRef(new Animated.Value(0)).current;

  const handleAnimation = () => {
    const sequence = [
      Animated.timing(animated, {
        toValue: 1.0,
        duration: 30,
        easing: Easing.linear,
        useNativeDriver: false,
      }),
      // rotate in other direction, to minimum value (= twice the duration of above)
      Animated.timing(animated, {
        toValue: -1.0,
        duration: 100,
        easing: Easing.linear,
        useNativeDriver: false,
      }),
      // return to begin position
      Animated.timing(animated, {
        toValue: 0.0,
        duration: 30,
        easing: Easing.linear,
        useNativeDriver: false,
      }),
    ];

    // A loop is needed for continuous animation
    Animated.loop(
      // Animation consists of a sequence of steps
      Animated.sequence([
        Animated.delay(2000),
        // start rotation in one direction (only half the time is needed)
        ...sequence,
        ...sequence,
        ...sequence,
      ])
    ).start();
  };

  const rotate = animated.interpolate({
    inputRange: [-1, 1],
    outputRange: ['-0.2rad', '0.2rad'],
  });

  useEffect(() => {
    if (animate) {
      handleAnimation();
    }
  }, [animate]);

  return (
    <Animated.View
      style={[
        style,
        {
          transform: [
            {
              rotate,
            },
          ],
        },
      ]}
    >
      {children}
    </Animated.View>
  );
};

export default ShakeAnimated;
