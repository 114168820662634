export default {
  colorPrimary: "#E05151",
  colorPrimaryDark: "#FFC914",
  colorPrimaryTransparent: "rgba(255, 212, 43, 0.1)",
  navyblue: "#006DBC",
  transparent: "transparent",
  translucent: "rgba(0, 0, 0, 0.5)",
  translucent1: "rgba(0, 0, 0, 0.08)",
  translucent2: "rgba(0, 0, 0, 0.03)",
  translucent3: "rgba(0, 0, 0, 0.7)",
  white: "#FFFFFF",
  white1: "rgba(255, 255, 255, 0.2)",
  white2: "#D9D9D9",
  red: "red",
  red1: "#FE2424",
  red2: "#FF4F79",
  red3: "#A80909",
  red4: "#ED0808",
  red5: "#4E0408",
  red6: "#FF0000",
  red7: "#D10000",
  red8: "rgba(106, 1, 1, 0.7)",
  blue: "#00649C",
  blue1: "#2B70E5",
  skyblue: "#03FFFF",
  skyblue1: "#86F4EE",
  skyblue2: "#49FFD3",
  black: "#101010",
  black1: "#212221",
  black2: "#rgba(0,0,0,0.2)",
  brown: "#3B1717",
  green: "green",
  green1: "#3E9D1C",
  green2: "#05AD65",
  green3: "#29FF3F",
  green4: "#8EFF49",
  grayPrimary: "#A3A3A3",
  gray: "gray",
  gray1: "#4E8979",
  gray2: "#C9C9C9",
  gray3: "#6D6E71",
  orange: "#F26234",
  yellow: "yellow",
  yellow1: "#FFEF5F",
  yellow2: "#F5FF7C",
  yellow3: "#F3B513",
  yellow4: "#FFC149",
  yellow5: "#DFA249",
  pink: "#ED1C24",
  pink2: "#F04A49",
  purple: "#F10074",
  violet: "rgb(176,0,180)",
  violet1: "#6C0B7B",
  violet2: "#980CC9",
  violet3: "#4282FF",
  violet4: "#71308F",
  progress: "#f0771f",
};
