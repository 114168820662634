import React from "react";
import { useNavigation } from "@react-navigation/native";
import { View, Image, FlatList } from "react-native";
import ShopItem from "screens/buyshop/item/Shop";
import { BuyShop } from "utils/mockdata";
import useMetrics from "utils/metrics";
import useStyles from "./styles.css";
import { images } from "@src/theme";

const MoreCoins = () => {
  const styles = useStyles();
  const { verticalScale } = useMetrics();
  const navigation = useNavigation<any>();

  const onPurchase = React.useCallback((data: any) => {
    navigation.navigate("PaymentGateway", data);
  },[]);

  return (
    <View style={[styles.v_body, { width: "100%" }]}>
      <View style={[styles.v_details, { alignItems: "center" }]}>
        <View style={styles.v_top_row}>
          <Image
            style={styles.i_needmore}
            source={images.needmore}
            resizeMode="stretch"
          />
          <Image
            style={{ width: verticalScale(115), height: verticalScale(115) }}
            source={images.gw_shop_button}
            resizeMode="contain"
          />
        </View>

        <View style={styles.v_bottom_row}>
          <FlatList
            numColumns={3}
            data={BuyShop.Shop.slice(0,3)}
            style={{ width: "100%" }}
            keyExtractor={(item, index) => index.toString()}
            columnWrapperStyle={{ justifyContent: "center" }}
            ItemSeparatorComponent={() => <View style={{height: verticalScale(15)}} />}
            renderItem={({ item, index }) => {
              return <ShopItem key={index.toString()} {...{ item, onPress: onPurchase }} />;
            }}
          />
        </View>
      </View>
    </View>
  );
};

export default MoreCoins;
