import React from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useLobbyService } from "@src/ducks/hooks";
import { View, Image, Text } from "react-native";
import { ImageButton } from "components/button";
import minheight from "utils/minheight";
import useStyles from "./styles.css";
import { images } from "@src/theme";
import numeral from "numeral";

const PaymentConfirm = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const silver = route?.params?.silver;
  const navigation = useNavigation<any>();
  const { onChangeScreen, onPurchaseReset } = useLobbyService();
  const heightStyle = minheight({ height: "58%" });
  const textStyle = minheight({ bottom: "41%" });
  const isSubscription = route?.params?.isSubscription;

  const onPress = () => {
    navigation.navigate("Home");
    onPurchaseReset();
    onChangeScreen({ screen: "main" });
  };

  return (
    <View style={styles.container}>
      <View style={[styles.v_bodystyle, heightStyle]}>
        <Image
          style={styles.i_background}
          source={
            isSubscription
              ? images.bg_subscription_allset
              : images.bg_shop_allset
          }
          resizeMode="stretch"
        />
        <Text style={[styles.t_silver_coin, textStyle]}>
          {numeral(silver).format("0,000")}
        </Text>
        <ImageButton
          onPress={onPress}
          style={[styles.btn_collect, isSubscription && { width: "25%" }]}
          source={images.btn_lobby_back}
        />
      </View>
    </View>
  );
};

export default PaymentConfirm;
