import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import { colors } from "@src/theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "60%",
      height: "15%",
      borderRadius: 5,
      marginBottom: 20,
      flexDirection: "row",
      backgroundColor: colors.white,
    },
    errorStyle: {
      borderWidth: 2,
      borderColor: colors.red,
    },
    textError: {
      right: 10,
      zIndex: 2,
      fontSize: 10,
      color: colors.red,
      position: "absolute",
      alignSelf: "center",
      backgroundColor: "white",
      fontFamily: "Roboto_Regular",
    },
    input: {
      flex: 1,
      fontSize: 16,
      paddingLeft: 10,
      color: colors.black,
      fontFamily: "Roboto_Regular",
    },
    btn_icon: { position: "absolute", width: 24, height: 24, right: 10, alignSelf: "center", },
  },
  {
    [DEVICE_SIZES.MD]: {
      input: {
        fontSize: 13,
      },
    },
    [DEVICE_SIZES.SM]: {
      input: {
        fontSize: 12,
      },
    },
    [DEVICE_SIZES.XS]: {
      input: {
        fontSize: 12,
      },
    },
  }
);

export default useStyles;
