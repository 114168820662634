import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { verticalScale } from "react-native-size-matters";
import { StyleSheet } from "react-native";
import { colors } from "@src/theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      zIndex: 6,
      alignItems: "center",
      justifyContent: "center",
      ...StyleSheet.absoluteFillObject,
      backgroundColor: colors.translucent,
    },
    center_view: {
      width: verticalScale(300),
      height: verticalScale(200),
      alignItems: "center",
      justifyContent: "center",
    },
    btn_close: {
      zIndex: 4,
      width: "6%",
      height: "10%",
      top: "5%",
      right: "5%",
      position: "absolute",
    },
    i_background: {
      width: "100%",
      height: "100%",
      position: "absolute",
      zIndex: 2,
    },
  },
  {
    [maxSize(DEVICE_SIZES.MD)]: {
      center_view: {
        width: verticalScale(400),
        height: verticalScale(300),
      },
    },
  }
);

export default useStyles;