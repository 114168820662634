import { useWindowDimensions } from "react-native";
import { windowHeight } from "utils/dimension-helper";
export const isTablet = windowHeight > 700;

export default (style: any, minHeight: number = 700) => {
  const { height } = useWindowDimensions();
  const heightStyle = height > minHeight && style;

  return heightStyle;
};
