import React from "react";
import { selectForgotData } from "@src/ducks/slices/forgot.slice";
import { useAppSelector } from "@src/ducks/ducksHook";
import { ImageBackground } from "react-native";
import CheckEmail from "./CheckEmail";
import EnterEmail from "./EnterEmail";
import useStyles from "./styles.css";
import { images } from "@src/theme";

const Forgot = () => {
  const styles = useStyles();
  const forgot =  useAppSelector(selectForgotData);

  return (
    <ImageBackground
      source={images.gw_bg}
      style={[styles.container]}
      resizeMode="stretch"
    >
      {forgot.email ? <CheckEmail email={forgot.email} /> : <EnterEmail />}
    </ImageBackground>
  );
};

export default Forgot;
