import React, { useEffect } from "react";
import { selectedGoldGamesEnable } from "@src/ducks/slices/lobby.slice";
import { useAuthService, useGModeService } from "@src/ducks/hooks";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { selectedAuthEmail } from "@src/ducks/slices/auth.slice";
import { DimensionContext } from "@src/DimensionProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useRoute } from "@react-navigation/native";
import CoinsDialog from "./coins-dialog";
import GModeProvider from "./provider";
import useStyles from "./styles.css";
import { View } from "react-native";
import Drawer from "./actionbutton";
import GameView from "./GameView";

const GameMode = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const sendDataToGTM = useGTMDispatch();
  const gameTypeID = route?.params?.gameTypeID;
  const { onBackgrounsSoundState } = useAuthService();
  const emailAddress = useAppSelector(selectedAuthEmail);
  const { onGModeRequest, onLeaveGModeRequest } = useGModeService();
  const isGoldGamesEnable = useAppSelector(selectedGoldGamesEnable);
  const { baseWidth, height } = React.useContext(DimensionContext);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onBackgrounsSoundState(false);
      clearTimeout(timeout);
    }, 1000);
    onGModeRequest({
      gamecode: route.params.code,
      gameTypeID: route.params.gameTypeID,
      packageFilePath: route.params.packageFilePath,
      currency: isGoldGamesEnable ? "GOLD" : "SILVER",
    });
    sendDataToGTM({
      event: "game_event",
      game_name: route.params.gameName,
      email: emailAddress,
      game_provider: gameTypeID === 5 ? "Lion Dragon Games" : "Evo Games",
      dataLayer: {
        game_name: route.params.gameName,
        email: emailAddress,
        game_provider: gameTypeID === 5 ? "Lion Dragon Games" : "Evo Games",
      },
    });
    return () => {
      onLeaveGModeRequest(route.params.gameCategoryID);
      onBackgrounsSoundState(true);
    };
  }, []);

  return (
    <GModeProvider>
      <View style={[styles.container, { width: baseWidth, height}]}>
        <GameView />
        <Drawer />
        <CoinsDialog />
      </View>
    </GModeProvider>
  );
};

export default React.memo(GameMode);
