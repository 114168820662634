import React from "react";
import { useErrorService } from "@src/ducks/hooks";
import { View, Image, Text } from "react-native";
import { ImageButton } from "components/button";
import useStyles from "./styles.css";
import { images } from "@src/theme";
import _ from "lodash";

const ErrorPopup = () => {
  const styles = useStyles();
  const { errorMessage, onResetErrorMessage } = useErrorService();

  if(_.isEmpty(errorMessage)){
    return null;
  }

  return (
    <View style={styles.container}>
      <View style={styles.bodyStyle}>
        <Image
          style={styles.i_background}
          source={images.bg_error}
          resizeMode="stretch" />
        <ImageButton
          onPress={onResetErrorMessage}
          style={styles.btn_close}
          source={images.btn_close} />
        <View style={styles.v_error}>
          <Text style={styles.t_error}>
            {errorMessage}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default ErrorPopup;
