import React from "react";
import { View, Modal, Pressable, StyleSheet, Image } from "react-native";
import { verticalScale } from "react-native-size-matters";
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
} from "react-native-reanimated";
import images from "theme/images";
import colors from "theme/colors";

type Props = {
  visible: boolean;
  onClose: () => void;
};

const SuccessTop = (props: Props) => {
  const viewref = React.useRef<any>();
  const animated = useSharedValue(-100);

  const onClose = () => {
    animated.value = withTiming(-100, { duration: 500 });
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      props.onClose();
    }, 600);
  };

  const animateStyle = useAnimatedStyle(() => {
    const translateY = animated.value;

    return {
      transform: [{ translateY }],
    };
  }, []);

  React.useEffect(() => {
    if (viewref && viewref.current) {
      animated.value = withTiming(100, { duration: 500 });
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        onClose();
      }, 3000);
    }
  }, [viewref, props.visible]);

  if (!props.visible) {
    return null;
  }

  return (
    <Modal
      transparent
      visible={props.visible}
      animationType={"none"}
      onRequestClose={onClose}
      supportedOrientations={[
        "portrait",
        "portrait-upside-down",
        "landscape",
        "landscape-left",
        "landscape-right",
      ]}
    >
      <View style={styles.container}>
        <Pressable style={styles.flex1} onPress={onClose} />
        <Animated.View
          ref={viewref}
          style={[
            styles.v_modal,
            animateStyle,
          ]}
        >
          <Image style={{flex: 1}} source={images.thank_you} resizeMode="contain" />
        </Animated.View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    zIndex: 2,
    alignItems: "center",
    backgroundColor: colors.transparent,
    ...StyleSheet.absoluteFillObject,
  },
  flex1: { flex: 1 },
  v_modal: {
    width: verticalScale(200),
    minHeight: verticalScale(50),
    position: "absolute",
  },
});

export default SuccessTop;
