import React from "react";
import { StyleSheet, View, ActivityIndicator } from "react-native";

const Loading = (props: any) => {
  const { customStyle, color, isLoading, onLayout } = props;

  if (!isLoading) {
    return null;
  }

  return (
    <View style={[styles.container, customStyle]} onLayout={onLayout}>
      <View style={[styles.rounded]}>
        <ActivityIndicator
          size="large"
          color={color || "white"}
          animating={isLoading}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    zIndex: 15,
    width: "100%",
    height: "100%",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    ...StyleSheet.absoluteFillObject,
  },
  rounded: {
    padding: 20,
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default Loading;
