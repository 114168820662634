import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import { colors } from "@src/theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      zIndex: 10,
      width: "100%",
      height: "100%",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.black1,
    },
    imageBorderStyle: {
      width: 140,
      height: 140,
    },
    p_rotate_style: {
      width: 80,
      height: 80,
    },
    t_loading: {
      fontSize: 16,
      color: colors.white,
      marginVertical: "6%",
      fontFamily: "Roboto_Regular",
    },
    btnLogin: { height: 80, width: "40%", marginTop: "5%" },
  },
  {
    [DEVICE_SIZES.MD]: {
      btnLogin: {
        height: 65,
      },
    },
    [DEVICE_SIZES.SM]: {
      btnLogin: {
        height: 60,
      },
    },
    [DEVICE_SIZES.XS]: {
      btnLogin: {
        height: 60,
      },
    },
  }
);

export default useStyles;
