import { useMemo } from "react";
import { useWindowDimensions } from "react-native";
import { DEVICE_SIZES, useDeviceSize } from "rn-responsive-styles";

const useConfig = () => {
  const { width } = useWindowDimensions();
  const device_size = useDeviceSize();
  const { SIZE, ITEM_SIZE } = useMemo(() => {
    switch (device_size) {
    case DEVICE_SIZES.MD:
      return { SIZE: 60, ITEM_SIZE: 40 };
    case DEVICE_SIZES.SM:
      return { SIZE: 50, ITEM_SIZE: 30 };
    case DEVICE_SIZES.XS:
      return { SIZE: 45, ITEM_SIZE: 30 };
    default:
      return { SIZE: 90, ITEM_SIZE: 60 };
    }
  }, [device_size]);
  const PADDING = SIZE / 3;
  const SIZE_PADDING = SIZE + PADDING;
  const cornerPositions = [
    { x: PADDING, y: PADDING },
    { x: width - SIZE_PADDING, y: PADDING },
    // { x: PADDING, y: height - SIZE_PADDING },
    // { x: width - SIZE_PADDING, y: height - SIZE_PADDING },
  ];

  return { cornerPositions, SIZE, ITEM_SIZE };
};

export default useConfig;
