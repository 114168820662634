import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { StyleSheet } from "react-native";
import { colors } from "@src/theme";
import { moderateScale, verticalScale } from "react-native-size-matters";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      zIndex: 4,
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      ...StyleSheet.absoluteFillObject,
    },
    center_view: {
      width: "80%",
      height: "86%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_background: {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    v_center: {
      width: "96%",
      height: "92%",
      top: "-1%",
      left: "-0.5%",
      borderRadius: 20,
      alignItems: "center",
    },
    btn_close: {
      width: "5%",
      height: "9%",
      top: "5%",
      right: "5%",
      position: "absolute",
      zIndex: 10,
    },
    v_inbox: {
      top: "-9%",
      marginLeft: "1%",
      width: "15.5%",
      height: "13%",
      position: "absolute",
      alignSelf: "center",
    },
    i_inbox: {
      width: "100%",
      height: "100%",
    },
    i_message: {
      width: "17%",
      height: "30%",
      position: "absolute",
      top: "-2%",
      right: "-2%",
    },

    // INBOX LIST STYLE
    v_body: {
      zIndex: 2,
      width: "94%",
      height: "100%",
      paddingLeft: "5%",
      paddingRight: "1%",
      justifyContent: "center",
    },
    t_subject: {
      fontFamily: "Roboto_Bold",
      fontSize: moderateScale(20),
      color: colors.yellow4,
    },
    t_details: {
      marginTop: 10,
      fontFamily: "Roboto_Bold",
      fontSize: moderateScale(19),
      color: colors.white,
    },
    default_details: {
      width: "100%",
      height: "80%",
      marginTop: "3%",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    v_details: {
      width: "100%",
      height: "80%",
      marginTop: "3%",
    },
    v_info: {
      paddingTop: "3%",
      width: "60%",
      height: "100%",
    },
    v_icon: {
      // width: "40%",
      // height: "100%",
      alignItems: "center",
      justifyContent: "center",
      width: verticalScale(180),
      height: verticalScale(180),
    },
    i_icon: { width: "100%", height: "86%" },

    // TROPHY
    v_top_body: {
      width: "100%",
      height: "100%",
      paddingTop: 10,
    },
    v_top_player: {
      width: "100%",
      height: "100%",
      // backgroundColor: 'red',
      alignItems: "center",
      justifyContent: "center",
    },
    i_trophy: {
      width: "27%",
      height: "30%",
    },
    top_info: {
      width: "95%",
      height: "40%",
      alignItems: "center",
      justifyContent: "center",
    },
    t_congrats: {
      fontFamily: "Roboto_Bold",
      fontSize: 12,
      color: colors.green4,
    },
    t_place: {
      fontFamily: "Roboto_Bold",
      fontSize: 12,
      textAlign: "center",
      color: colors.skyblue2,
    },
    t_place_color: {
      color: "#F4BF61",
    },

    top_silver: {
      width: "80%",
      height: "30%",
      alignItems: "center",
      justifyContent: "center",
    },
    top_silver_icon: { width: "100%", height: "100%" },
    t_silver_amount: {
      fontFamily: "Roboto_Bold",
      fontSize: 30,
      color: colors.skyblue2,
      position: "absolute",
    },

    // STARTER PACK
    i_pack: { width: "100%", height: "100%", transform: [{ scale: 1.2 }] },
    btn_buynow: {
      bottom: "2%",
      position: "absolute",
    },

    // TRY GAMES
    i_games: { width: "70%", height: "80%" },
    i_text: { width: "80%", height: "23%" },
    i_avatar: {
      top: "20%",
      width: "30%",
      height: "80%",
      position: "absolute",
      left: 0,
      zIndex: 2,
    },
    btn_playnow: {
      right: "2%",
      bottom: "15%",
      position: "absolute",
    },

    // NEED MORE COINS
    i_needmore: { width: "80%", height: "100%" },
    v_top_row: {
      flexDirection: "row",
      width: "100%",
      height: "25%",
    },
    v_bottom_row: {
      flexDirection: "row",
      width: "100%",
      height: "66%",
      marginTop: "3%",
      alignItems: "center",
      justifyContent: "space-evenly",
    },
  },
  {
    [maxSize(DEVICE_SIZES.MD)]: {
      t_subject: {
        fontSize: moderateScale(15),
      },
      t_details: {
        fontSize: moderateScale(13),
      },
      v_icon: {
        width: verticalScale(140),
        height: verticalScale(150),
      },
    },
  }
);

export default useStyles;
