import React from "react";
import { View } from "react-native";
import EnterFields from "./EnterFields";
import useStyles from "./styles.css";

export default () => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <EnterFields />
    </View>
  );
};
