import React from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
} from "react-native-reanimated";
import { AuthContext } from "@src/AuthProvider";
import SVGIcon from "@assets/svg";

const AnimatedButton = Animated.createAnimatedComponent(Pressable);

const Button = (props: any) => {
  const animated = useSharedValue(1);
  const { handleClick } = React.useContext(AuthContext);
  const { style, onPress, disabled, scaleSize } = props;

  const onPressIn = () => {
    animated.value = withTiming(scaleSize || 0.9, { duration: 100 });
  };

  const onPressOut = () => {
    animated.value = withTiming(1, { duration: 100 });
  };

  const onClick = () => {
    onPress();
    handleClick();
  };

  const animateStyle = useAnimatedStyle(() => {
    return {
      transform: [{ scale: animated.value }],
    };
  }, []);

  return (
    <AnimatedButton
      onPress={onClick}
      disabled={disabled}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      style={[style, animateStyle]}
    >
      <View style={styles.v_background}>
        <SVGIcon name="btn-big" width={props.width} height={props.height} />
      </View>
      <View style={[styles.v_label, 
        { width: props.width * 0.938, height: props.height * 0.82}
      ]}>
        <Text selectable={false} style={[styles.t_label, props.labelStyle]}>{props.label}</Text>
      </View>
    </AnimatedButton>
  );
};

const styles = StyleSheet.create({
  v_background: { position: "absolute" },
  v_label: {position: "absolute", width: "93.8%", height: "82%",
    alignItems: "center", justifyContent: "center"},
  t_label: { fontFamily: "Roboto_Bold", color: "white", textAlign: "center"}
});

export default Button;
