import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "@src/theme";
import { verticalScale } from "react-native-size-matters";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      zIndex: 2,
      alignItems: "center",
      justifyContent: "center",
    },
    center_view: {
      width: "75%",
      height: "86%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_background: {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    btn_close: {
      top: "5%",
      zIndex: 200,
      width: "5%",
      right: "5%",
      height: "9%",
      position: "absolute",
    },
    v_inbox: {
      top: "-5%",
      width: "15%",
      height: "12%",
      position: "absolute",
      alignSelf: "center",
    },
    i_inbox: {
      width: "100%",
      height: "100%",
    },
    i_message: {
      width: "17%",
      height: "30%",
      position: "absolute",
      top: "-2%",
      right: "-2%",
    },

    // INBOX LIST STYLE
    v_body: {
      zIndex: 2,
      bottom: "1%",
      width: "94%",
      height: "85%",
      alignItems: "center",
      paddingHorizontal: "4%",
      justifyContent: "center",
    },
    flatlist: { width: "100%" },
    height20: { height: 20 },

    // INBOX ITEM COMPONENTS STYLES
    v_item: {
      width: "92%",
      height: "20%",
      paddingLeft: "7%",
      // paddingRight: "1.5%",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    i_bg_message: {
      width: "100%",
      height: "82%",
      position: "absolute",
    },
    i_item_icon: { width: "14%", height: "100%" },
    v_subject: {flex: 1, marginLeft: "1.5%", marginRight: 15, alignItems: "flex-start", justifyContent: "center"},
    t_notif: {
      fontSize: verticalScale(14),
      lineHeight: verticalScale(15),
      color: colors.yellow2,
      fontFamily: "Roboto_Bold",
    },
    btn_read: { top: "5%", width: "17%", height: "60%", marginRight: "2%" },
    btn_notif_close: { width: "4%", height: "30%" },
  },
  {
    [maxSize(DEVICE_SIZES.MD)]: {
      t_notif: { fontSize: verticalScale(12), lineHeight: verticalScale(13) },
    },
  }
);

export default useStyles;
