import React from "react";
import { View, Image, Text, Linking, Pressable } from "react-native";
import { ImageButton } from "@src/components/button";
import Icon from "@expo/vector-icons/MaterialIcons";
import { useAuthService } from "@src/ducks/hooks";
import { Scale } from "@src/utils/animation";
import useMetrics from "@src/utils/metrics";
import useStyles from "./styles.css";
import { images } from "@src/theme";

const TermsAndCondition = () => {
  const styles = useStyles();
  const [is18, set18] = React.useState(false);
  const [error, setError] = React.useState(false);
  const { onTermsAndConditionChange } = useAuthService();
  const { verticalScale, moderateHScale } = useMetrics();
  const onContinue = () => {
    if (!is18) {
      setError(true);
    } else {
      onTermsAndConditionChange(true);
    }
  };
  const onTerms = () =>
    Linking.openURL("https://fortune8.online/web-terms-%2B-conditions");

  return (
    <View style={styles.container}>
      <Scale style={styles.container}>
        <View
          style={[
            styles.v_bodystyle,
            { width: verticalScale(1000), height: verticalScale(580) },
          ]}
        >
          <Image
            style={styles.i_background}
            source={images.bg_transparent}
            resizeMode="stretch"
          />
          <View style={styles.v_center}>
            <View style={styles.v_body_wrapper}>
              <Image
                style={{ width: "100%", height: "60%", marginTop: "5%" }}
                source={images.t_terms}
                resizeMode="stretch"
              />
              <Text style={[styles.t_read, { fontSize: moderateHScale(20) }]}>
                Read:{" "}
                <Text style={{ color: "#0FEEB8" }} onPress={onTerms}>
                  Terms and Condition
                </Text>
              </Text>
              <Pressable
                onPress={() => {
                  set18(!is18);
                  setError(false);
                }} style={styles.v_declare}>
                <View
                  style={[
                    styles.v_check,
                    is18 && { backgroundColor: "black" },
                    { width: moderateHScale(20), height: moderateHScale(20) },
                  ]}
                >
                  {is18 && (
                    <Icon
                      name="check"
                      color="white"
                      size={moderateHScale(20)}
                    />
                  )}
                </View>
                <Text
                  style={[
                    styles.t_declare,
                    error && { color: "red" },
                    { fontSize: moderateHScale(20) },
                  ]}
                >
                  I declare I am 18+ years of age
                </Text>
              </Pressable>
            </View>
            <View style={styles.v_button}>
              <ImageButton
                onPress={onContinue}
                style={styles.btn_collect}
                source={images.btn_continue}
              />
            </View>
          </View>
        </View>
      </Scale>
    </View>
  );
};

export default React.memo(TermsAndCondition);
