import React, { useState } from "react";
import TierLevelReward from "./TierLevelReward";
import TierInfoMore from "./TierInfoMore";

const TierLevelRewards = () => {
  const [viewmore, setViewmore] = useState(false);

  return viewmore ? (
    <TierInfoMore onBack={() => setViewmore(false)} />
  ) : (
    <TierLevelReward onPress={() => setViewmore(true)} />
  );
};

export default TierLevelRewards;