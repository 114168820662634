import { SagaIterator } from "@redux-saga/core";
import { call, delay, put, select, takeEvery } from "redux-saga/effects";

// Types
import * as Types from "../types";

// API
import {
  fetchGameURLCurrency,
  fetchGameURLSwordPlay,
  fetchMissions,
  fetchUserRank,
  jadeEggAccumulation,
  swordplayPaymentCallback,
  swordplayPaymentInit,
  walletBalance
} from "@src/utils/api";

// Slice
import _ from "lodash";
import moment from "moment";
import { compareMissionsBeforeAndAfter } from "utils/transform-helper";
import { authActions, selectedAuthSession } from "../slices/auth.slice";
import { gmodeActions } from "../slices/gmode.slice";
import { lobbyActions, selectedMissions } from "../slices/lobby.slice";

function* handleGameModeRequest(action: {
  type: typeof gmodeActions.gmodeRequest;
  payload: Types.GameMode;
}): SagaIterator {
  try {
    const gameTypeID = action.payload.gameTypeID;
    const session = yield select(selectedAuthSession);

    let result = null;

    if (gameTypeID === 7) {
      const timestamp = +moment();
      result = yield call(fetchGameURLSwordPlay, {...action.payload, ...session, timestamp});
    }else{
      result = yield call(fetchGameURLCurrency, {...action.payload, ...session });
    }

    if (!_.isEmpty(result.error)) {
      throw {
        code: 401,
        message: "Unable to load game. Please come back later",
      };
    }
    
    let link = result?.data?.link ?? "";
    link = link.replace("http://play", "https://play");

    yield put(gmodeActions.gmodeSuccess(link));
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.setErrorMessage("Session Expired"));
      yield put(authActions.logout());
    }
    yield put(gmodeActions.gmodeFailure(error?.message ?? error));
  }
}

function* handleMissionsRequest(): SagaIterator {
  try {
    const session = yield select(selectedAuthSession);
    const incompleteMission = yield select(selectedMissions);
    const completedMission = yield call(fetchMissions, session);
    const updatedMissions = yield call(compareMissionsBeforeAndAfter,incompleteMission, completedMission.data);
    yield put(lobbyActions.missions(updatedMissions));
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Session Expired"));
    }
  }
}

function* handleSwordplayRequest(action: {
  type: typeof gmodeActions.topupSwordPlayRequest;
  payload: Types.SwordPlayInit & Types.Token;
}): SagaIterator {
  try {
    const session = yield select(selectedAuthSession);

    const payment_init_params = {
      currency: action.payload.currency || "SILVER",
      cpOrderId: action.payload.cpOrderId,
      extParams: action.payload.extParams,
      productDesc: action.payload.productDesc,
      productId: action.payload.productId,
      productName: action.payload.productName,
      productNumber: action.payload.productNumber,
      productPrice: action.payload.productPrice || 0,
      serverId: action.payload.serverId,
      userId: session.userId,
    };

    if(action.payload.currency === "SILVER"){
      const price = Math.round(action.payload.productPrice || 0);
      payment_init_params.productPrice = price * 50000;
    }

    const payment_callback_params = {
      account: session.userId,
      coin: Math.round(action.payload.productPrice || 0),
      cpOrderId: action.payload.cpOrderId,
      money: action.payload.productPrice,
      productId: action.payload.productId,
      serverId: action.payload.serverId,
    };

    yield call(swordplayPaymentInit, payment_init_params, session.token);
    const paymentcallback = yield call(swordplayPaymentCallback, payment_callback_params, session.token);

    yield put(gmodeActions.topupSwordPlaySuccess(paymentcallback));
    yield delay(1000);
    yield put(gmodeActions.topupSwordPlaySuccess({}));
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Session Expired"));
    }
    yield put(gmodeActions.topupSwordPlayFailure(error?.error ?? error));
  }
}

function* handleGameModeLeave(): SagaIterator {
  try {
    const session = yield select(selectedAuthSession);

    const balance = yield call(walletBalance, session);
    yield put(authActions.updateWallet(balance.data));

    const userRank = yield call(fetchUserRank, session);
    yield put(lobbyActions.userRank(userRank));

    const jadeEgg = yield call(jadeEggAccumulation, session);
    yield put(lobbyActions.jadeAmount(jadeEgg.data.amount));

  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Session Expired"));
    }
  }
}

// Watcher Saga
function* gmodeWatcherSaga(): SagaIterator {
  yield takeEvery(gmodeActions.gmodeRequest.type, handleGameModeRequest);
  yield takeEvery(gmodeActions.missionRequest.type, handleMissionsRequest);
  yield takeEvery(gmodeActions.topupSwordPlayRequest.type, handleSwordplayRequest);
  yield takeEvery(gmodeActions.gameModeLeave.type, handleGameModeLeave);
}

export default gmodeWatcherSaga;
