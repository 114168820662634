import React, { useEffect } from 'react';
import { Dimensions, StyleSheet } from 'react-native';
import Animated, {
  withTiming,
  withRepeat,
  useSharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { LinearGradient } from 'expo-linear-gradient';

const SCREENW = Dimensions.get('window').width;

const ShinyEffect = () => {
  const transX = useSharedValue(-50);
  const shineStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: transX.value }],
    };
  }, []);

  useEffect(() => {
    transX.value = withRepeat(
      withTiming(SCREENW, { duration: 3000 }),
      25,
      false
    );
  }, []);

  return (
    <Animated.View style={[styles.constainer, shineStyle]}>
      <LinearGradient
        style={styles.linearStyle}
        useAngle={true}
        angle={90}
        angleCenter={{ x: 0.5, y: 0.5 }}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 1 }}
        colors={[
          '#ffffff00',
          '#ffffff20',
          '#ffffffB3',
          '#ffffff20',
          '#ffffff00',
        ]}
      />
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  constainer: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 3,
  },
  linearStyle: {
    ...StyleSheet.absoluteFillObject,
    width: 100,
  },
});

export default ShinyEffect;
