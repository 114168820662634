import { Asset } from "expo-asset";

const images: any = {
  // JADE EGG
  "0percent": require("../../assets/json/jade-egg/shake/images/img_2.png"),
  "25percent": require("../../assets/json/jade-egg/shake/images/img_3.png"),
  "50percent": require("../../assets/json/jade-egg/shake/images/img_4.png"),
  "75percent": require("../../assets/json/jade-egg/shake/images/img_5.png"),
  "100percent": require("../../assets/json/jade-egg/shake/images/img_6.png"),

  // DRAWER
  "side-bar": require("../../assets/images/drawer/side-bar.webp"),

  // GAME THUMBNAIL PLACEHOLDER
  "game_thumb_placeholder": require("../../assets/images/game-thumbnail-placeholder.png"),

  // THUMBNAILS
  "th_dragonroller": require("../../assets/images/thumbnails/th_dragonroller.webp"),
  "th_highroller": require("../../assets/images/thumbnails/th_highroller.webp"),
  "th_hotdeals": require("../../assets/images/thumbnails/th_hotdeals.webp"),
  "th_starterpack": require("../../assets/images/thumbnails/th_starterpack.webp"),
  "th_tgif": require("../../assets/images/thumbnails/th_tgif.webp"),
  "th_weeklybooster": require("../../assets/images/thumbnails/th_weeklybooster.webp"),

  // DAILY BONUS
  "img_0_01": require("../../assets/json/daily-bonus/random-slot/images/img_0_01.png"),
  "img_0_03": require("../../assets/json/daily-bonus/random-slot/images/img_0_03.png"),
  "img_0_06": require("../../assets/json/daily-bonus/random-slot/images/img_0_06.png"),
  "img_0_07": require("../../assets/json/daily-bonus/random-slot/images/img_0_07.png"),
  "img_0_08": require("../../assets/json/daily-bonus/random-slot/images/img_0_08.png"),
  "img_0_09": require("../../assets/json/daily-bonus/random-slot/images/img_0_09.png"),
  "img_0_11": require("../../assets/json/daily-bonus/random-slot/images/img_0_11.png"),
  "img_0_88": require("../../assets/json/daily-bonus/random-slot/images/img_0_88.png"),

  // AMOUNT
  "150k": require("../../assets/images/amount/150k.png"),
  "200k": require("../../assets/images/amount/200k.png"),
  "250k": require("../../assets/images/amount/250k.png"),
  "300k": require("../../assets/images/amount/300k.png"),
  "350k": require("../../assets/images/amount/350k.png"),
  "400k": require("../../assets/images/amount/400k.png"),
  "450k": require("../../assets/images/amount/450k.png"),
  "500k": require("../../assets/images/amount/500k.png"),

  // ADS FOLDER
  featured: require("../../assets/images/ads/featured.png"),
  bg_ads: require("../../assets/images/ads/bg-ads.webp"),
  DungeonImmortalEvil: require("../../assets/images/ads/DungeonImmortalEvil.png"),
  NaughtyGirlsCabaret: require("../../assets/images/ads/NaughtyGirlsCabaret.png"),
  TreeOfLight: require("../../assets/images/ads/TreeOfLight.png"),
  NukeWorld: require("../../assets/images/ads/NukeWorld.png"),
  PachinGirl: require("../../assets/images/ads/PachinGirl.png"),
  OpeningSpecial: require("../../assets/images/ads/OpeningSpecial.png"),
  swordplay: require("../../assets/images/ads/swordplay.png"),

  // MAIN FOLDER
  fortune_logo: require("../../assets/images/main/fortune_logo.png"),
  phone_rotate: require("../../assets/images/main/phone_rotate.png"),
  logo_bitpace: require("../../assets/images/main/logo_bitpace.png"),
  bg_popup: require("../../assets/images/main/bg_popup.png"),
  gameworkz: require("../../assets/images/main/gameworkz.png"),
  bg_progress: require("../../assets/images/main/bg_progress.png"),
  fb_register: require("../../assets/images/main/fb_register.png"),
  bg_transparent: require("../../assets/images/main/bg_transparent.png"),
  maintenance_screen: require("../../assets/images/main/maintenance_screen.png"),
  mainCreateAccountTitle: require("../../assets/images/main/main_create_account_title.png"),
  mainRegisterGoogleButton: require("../../assets/images/main/main_register_google_button.png"),
  mainRegisterEmailButton: require("../../assets/images/main/main_register_button.png"),
  main_login_button: require("../../assets/images/main/main_login_button.png"),
  t_terms: require("../../assets/images/main/t_terms.png"),

  // BUTTON FOLDER
  btnClose: require("../../assets/images/buttons/btn_close.png"),
  btn_readmore: require("../../assets/images/buttons/btn_readmore.png"),
  btnTier: require("../../assets/images/buttons/btnTier.png"),
  btnBack: require("../../assets/images/buttons/btnBack.png"),
  btnSubmit: require("../../assets/images/buttons/btnSubmit.png"),
  btnResetPassword: require("../../assets/images/buttons/btnResetPassword.png"),
  btnChangeAvatar: require("../../assets/images/buttons/btnChangeAvatar.png"),
  btnEditProfile: require("../../assets/images/buttons/btnEditProfile.png"),
  btn_update: require("../../assets/images/buttons/btn_update.png"),
  btn_done: require("../../assets/images/buttons/btn_done.png"),
  btn_buy_now: require("../../assets/images/buttons/btn_buy_now.png"),
  btn_back_profile: require("../../assets/images/buttons/btn_back_profile.png"),
  btn_exchange_gold: require("../../assets/images/buttons/btn_exchange_gold.png"),
  btn_exchange_gold_disable: require("../../assets/images/buttons/btn_exchange_gold_disable.png"),
  btn_lobby_back: require("../../assets/images/buttons/btn_lobby_back.png"),
  btn_login_play: require("../../assets/images/buttons/btn_login_play.png"),
  m_btn_update: require("../../assets/images/buttons/m_btn_update.png"),
  m_btn_play: require("../../assets/images/buttons/m_btn_play.png"),
  m_btn_done: require("../../assets/images/buttons/m_btn_done.png"),
  m_btn_buynow: require("../../assets/images/buttons/m_btn_buynow.png"),
  m_btn_collect: require("../../assets/images/buttons/m_btn_collect.png"),
  btn_continue: require("../../assets/images/buttons/btn_continue.png"),
  btn_playnow: require("../../assets/images/buttons/btn_playnow.png"),

  // LOGIN FOLDER
  gw_bg: require("../../assets/images/lobby/home_bg.webp"),
  login_text: require("../../assets/images/login/login_text.png"),
  btn_login: require("../../assets/images/login/btn_login.png"),
  btn_google: require("../../assets/images/login/btn_google.png"),
  btn_facebook: require("../../assets/images/login/btn_facebook.png"),

  // SIGNUP FOLDER
  btn_back: require("../../assets/images/signup/btn_back.png"),
  create_text: require("../../assets/images/signup/create_text.png"),
  btn_signup: require("../../assets/images/signup/btn_signup.png"),
  congrats_text: require("../../assets/images/signup/congrats_text.png"),
  btn_login_again: require("../../assets/images/signup/btn_login_again.png"),

  // FORGOT FOLDER
  forgot: require("../../assets/images/forgot/forgot.png"),
  btn_close: require("../../assets/images/forgot/btn_close.png"),
  success: require("../../assets/images/forgot/success.png"),
  check_email: require("../../assets/images/forgot/check_email.png"),
  reset_password: require("../../assets/images/forgot/reset_password.png"),

  // TOPNAV FOLDER
  hud_top: require("../../assets/images/topnav/hud_top.webp"),
  btn_home: require("../../assets/images/topnav/btn_home.webp"),
  btn_mail: require("../../assets/images/topnav/btn_mail.webp"),
  btn_switch: require("../../assets/images/topnav/btn_switch.webp"),
  btn_settings: require("../../assets/images/topnav/btn_settings.webp"),
  silver_bar: require("../../assets/images/topnav/silver_bar.webp"),
  gold_bar: require("../../assets/images/topnav/gold_bar.webp"),
  avatar_bg: require("../../assets/images/topnav/avatar_bg.webp"),
  avatar_shine: require("../../assets/images/topnav/avatar_shine.webp"),
  gold_pot: require("../../assets/images/topnav/gold_pot.png"),
  gwz_shop: require("../../assets/images/topnav/gwz_shop.png"),
  gwz_gold: require("../../assets/images/topnav/gwz_gold.png"),
  bg_gold: require("../../assets/images/topnav/bg_gold.png"),
  Egg_Jade: require("../../assets/images/topnav/Egg_Jade.png"),
  warning: require("../../assets/images/topnav/warning.png"),
  gold_dropdown: require("../../assets/images/topnav/gold_dropdown.webp"),

  // BOTTOM NAV FOLDER
  hud_bot: require("../../assets/images/bottomnav/hud_bot.webp"),
  btn_shop: require("../../assets/images/bottomnav/btn_shop.webp"),
  hud_bot_back: require("../../assets/images/bottomnav/hud_bot_back.png"),
  btn_bonus: require("../../assets/images/bottomnav/btn_bonus.webp"),
  btn_hourglass: require("../../assets/images/bottomnav/btn_hourglass.webp"),
  daily_timer: require("../../assets/images/bottomnav/daily_timer.webp"),
  gw_shop_button: require("../../assets/images/bottomnav/gw-shop-button.png"),

  // LOBBY FOLDER
  game_border: require("../../assets/images/lobby/game_border.png"),
  game_frame: require("../../assets/images/lobby/game_frame.png"),
  bg_error: require("../../assets/images/lobby/bg_error.png"),
  arrow_right: require("../../assets/images/lobby/arrow_right.png"),
  lobby_slots: require("../../assets/images/lobby/lobby-slots.png"),
  home_bg: require("../../assets/images/lobby/home_bg.webp"),
  lobby_table_games: require("../../assets/images/lobby/lobby-table-games.png"),
  lobby_instant_games: require("../../assets/images/lobby/lobby-instant-games.png"),
  lock_diamond: require("../../assets/images/lobby/lock_diamond.png"),
  lock_sapphire: require("../../assets/images/lobby/lock_sapphire.png"),
  lock_emerald: require("../../assets/images/lobby/lock_emerald.png"),
  label_emerald: require("../../assets/images/lobby/tier_emerald.png"),
  label_ruby: require("../../assets/images/lobby/tier_ruby.png"),
  label_diamond: require("../../assets/images/lobby/tier_diamond.png"),
  ic_top_game: require("../../assets/images/lobby/ic_top_game.png"),
  ic_level_up: require("../../assets/images/lobby/ic_level_up.png"),
  curtain: require("../../assets/images/lobby/curtain.png"),

  // AVATARS FOLDER
  avatar_bill_wb: require("../../assets/images/avatars/avatar_bill_wb.png"),
  avatar_casino_wb: require("../../assets/images/avatars/avatar_casino_wb.png"),
  avatar_cowboy_wb: require("../../assets/images/avatars/avatar_cowboy_wb.png"),
  avatar_cowgirl_wb: require("../../assets/images/avatars/avatar_cowgirl_wb.png"),
  avatar_gambler_wb: require("../../assets/images/avatars/avatar_gambler_wb.png"),
  avatar_gamer_wb: require("../../assets/images/avatars/avatar_gamer_wb.png"),
  avatar_glam_wb: require("../../assets/images/avatars/avatar_glam_wb.png"),
  avatar_hiphop_wb: require("../../assets/images/avatars/avatar_hiphop_wb.png"),
  avatar_popstar_wb: require("../../assets/images/avatars/avatar_popstar_wb.png"),
  avatar_rockstar_wb: require("../../assets/images/avatars/avatar_rockstar_wb.png"),
  avatar_frame: require("../../assets/images/avatars/avatar_frame.png"),

  // PROFILE FOLDER
  profile_frame: require("../../assets/images/profile/profile_frame.png"),
  your_tier: require("../../assets/images/profile/your_tier.png"),
  your_rank: require("../../assets/images/profile/your_rank.png"),
  bg_player: require("../../assets/images/profile/bg_player.png"),
  icon_edit: require("../../assets/images/profile/icon_edit.png"),
  tier_sapphire: require("../../assets/images/profile/tier_sapphire.png"),
  tier_ruby: require("../../assets/images/profile/tier_ruby.png"),
  tier_emerald: require("../../assets/images/profile/tier_emerald.png"),
  tier_diamond: require("../../assets/images/profile/tier_diamond.png"),
  bg_edit_name: require("../../assets/images/profile/bg_edit_name.png"),
  update_profile: require("../../assets/images/profile/update_profile.png"),
  tier_banner: require("../../assets/images/profile/tier_banner.png"),
  tier_rewards: require("../../assets/images/profile/tier_rewards.png"),
  tier_rewards2: require("../../assets/images/profile/tier_rewards2.png"),
  tier_bg: require("../../assets/images/profile/tier_bg.png"),
  tier_bg2: require("../../assets/images/profile/tier_bg2.png"),
  tier_btn_close: require("../../assets/images/profile/tier_btn_close.png"),

  // BONUS FOLDER
  wc_bonus: require("../../assets/images/bonus/wc_bonus.png"),
  vault_bg: require("../../assets/images/bonus/vault_bg.png"),
  btn_collect: require("../../assets/images/bonus/btn_collect.png"),
  bonus_back: require("../../assets/images/bonus/bonus_back.png"),
  btn_number: require("../../assets/images/bonus/btn_number.png"),
  choos_number: require("../../assets/images/bonus/choos_number.png"),
  silver_coin: require("../../assets/images/bonus/silver_coin.png"),

  // GOLDCOIN FOLDER
  goldexchange_title: require("../../assets/images/goldcoin/goldexchange_title.png"),
  goldexchange_notes: require("../../assets/images/goldcoin/goldexchange_notes.png"),

  // EGG JADE FOLDER
  behold_text: require("../../assets/images/eggjade/behold_text.png"),
  note_text: require("../../assets/images/eggjade/note_text.png"),
  dragon: require("../../assets/images/eggjade/dragon.png"),
  bg_egg_full: require("../../assets/images/eggjade/bg_egg_full.png"),
  bg_collected: require("../../assets/images/eggjade/bg_collected.webp"),
  btn_ok: require("../../assets/images/eggjade/btn_ok.png"),
  silver_coin_lbl: require("../../assets/images/eggjade/silver_coin_lbl.png"),
  silver_coin_frame: require("../../assets/images/eggjade/silver_coin_frame.png"),
  btn_hatch: require("../../assets/images/eggjade/btn_hatch.png"),
  btn_play_hatch: require("../../assets/images/eggjade/btn_play_hatch.png"),
  btn_hatch_now: require("../../assets/images/eggjade/btn_hatch_now.png"),
  btn_hatch_later: require("../../assets/images/eggjade/btn_hatch_later.png"),
  btnHome: require("../../assets/images/eggjade/home.webp"),

  // MISSION FOLDER
  border_keys: require("../../assets/images/mission/border_keys.png"),
  btn_mission: require("../../assets/images/mission/btn_mission.png"),
  bg_item_mission: require("../../assets/images/mission/bg_item_mission.png"),
  key: require("../../assets/images/mission/key.png"),
  note_1_key: require("../../assets/images/mission/note_1_key.png"),
  note_3_key: require("../../assets/images/mission/note_3_key.png"),
  mission_1key: require("../../assets/images/mission/mission_1key.png"),
  mission_3keys: require("../../assets/images/mission/mission_3keys.png"),
  vault_notes: require("../../assets/images/mission/vault_notes.png"),
  fortune_vault: require("../../assets/images/mission/fortune_vault.png"),
  mission_vault: require("../../assets/images/mission/mission_vault.png"),
  btn_open_vault: require("../../assets/images/mission/btn_open_vault.png"),
  btn_lock_vault: require("../../assets/images/mission/btn_lock_vault.png"),
  mission_completed: require("../../assets/images/mission/mission_completed.png"),
  task_completed: require("../../assets/images/mission/task_completed.png"),
  ic_mission_500k: require("../../assets/images/mission/ic_mission_500k.png"),
  ic_mission_1m: require("../../assets/images/mission/ic_mission_1m.png"),
  ic_mission_2m: require("../../assets/images/mission/ic_mission_2m.png"),
  ic_mission_change_avatar: require("../../assets/images/mission/ic_mission_change_avatar.png"),
  ic_mission_collect_bonus: require("../../assets/images/mission/ic_mission_collect_bonus.png"),
  ic_mission_daily_bonus: require("../../assets/images/mission/ic_mission_daily_bonus.png"),
  ic_mission_display_name: require("../../assets/images/mission/ic_mission_display_name.png"),
  ic_mission_instant_games: require("../../assets/images/mission/ic_mission_instant_games.png"),
  ic_mission_purchase_item: require("../../assets/images/mission/ic_mission_purchase_item.png"),
  ic_mission_slots: require("../../assets/images/mission/ic_mission_slots.png"),
  ic_mission_subscribe: require("../../assets/images/mission/ic_mission_subscribe.png"),
  ic_mission_table_games: require("../../assets/images/mission/ic_mission_table_games.png"),
  ic_mission_update_profile: require("../../assets/images/mission/ic_mission_update_profile.png"),
  open_vault_1m: require("../../assets/images/mission/open_vault_1m.png"),
  ic_open: require("../../assets/images/mission/ic_open.png"),
  text_complete_mission: require("../../assets/images/mission/text_complete_mission.png"),

  // SETTINGS FOLDER
  fb_share: require("../../assets/images/settings/fb_share.png"),
  btn_about_gold: require("../../assets/images/settings/btn_about_gold.webp"),

  // GAMEVIEW FOLDER
  fortune8: require("../../assets/images/gameview/fortune8.png"),
  shop: require("../../assets/images/gameview/shop.png"),
  ic_change: require("../../assets/images/gameview/ic_change.png"),
  ic_arrow: require("../../assets/images/gameview/ic_arrow.png"),
  arrow_red: require("../../assets/images/gameview/arrow_red.png"),
  switch_silver: require("../../assets/images/gameview/switch_silver.png"),
  play_silver: require("../../assets/images/gameview/play_silver.png"),
  play_gold: require("../../assets/images/gameview/play_gold.png"),
  select_coin: require("../../assets/images/gameview/select_coin.png"),
  close_circle: require("../../assets/images/gameview/close_circle.png"),

  // INBOX FOLDER
  bg_inbox: require("../../assets/images/inbox/bg_inbox.png"),
  bg_message: require("../../assets/images/inbox/bg_message.png"),
  btn_read: require("../../assets/images/inbox/btn_read.png"),
  logo_inbox: require("../../assets/images/inbox/logo_inbox.png"),
  bg_panel: require("../../assets/images/inbox/bg_panel.png"),
  ic_bonus: require("../../assets/images/inbox/ic_bonus.png"),
  ic_congrats: require("../../assets/images/inbox/ic_congrats.png"),
  ic_fortune: require("../../assets/images/inbox/ic_fortune.png"),
  ic_message: require("../../assets/images/inbox/ic_message.png"),
  ic_more_games: require("../../assets/images/inbox/ic_more_games.png"),
  ic_play: require("../../assets/images/inbox/ic_play.png"),
  ic_trophy_bronze: require("../../assets/images/inbox/ic_trophy_bronze.png"),
  ic_trophy_gold: require("../../assets/images/inbox/ic_trophy_gold.png"),
  ic_trophy_silver: require("../../assets/images/inbox/ic_trophy_silver.png"),
  ic_version_update: require("../../assets/images/inbox/ic_version_update.png"),
  ic_version: require("../../assets/images/inbox/ic_version.png"),
  ic_welcome: require("../../assets/images/inbox/ic_welcome.png"),
  ic_elves: require("../../assets/images/inbox/ic_elves.png"),
  ic_daily: require("../../assets/images/inbox/ic_daily.png"),
  ic_trophy: require("../../assets/images/inbox/ic_trophy.png"),
  ic_silver: require("../../assets/images/inbox/ic_silver.png"),
  ic_sale: require("../../assets/images/inbox/ic_sale.png"),
  ic_pachin: require("../../assets/images/inbox/ic_pachin.png"),
  ic_candy: require("../../assets/images/inbox/ic_candy.png"),
  ic_shop: require("../../assets/images/inbox/ic_shop.png"),
  cheaptuesday: require("../../assets/images/inbox/cheaptuesday.png"),
  tryluck: require("../../assets/images/inbox/tryluck.png"),
  loveslot: require("../../assets/images/inbox/loveslot.png"),
  pachin: require("../../assets/images/inbox/pachin.png"),
  candy: require("../../assets/images/inbox/candy.png"),
  needmore: require("../../assets/images/inbox/needmore.png"),

  // LEADERBOARD FOLDER
  l_logo: require("../../assets/images/leaderboard/l_logo.png"),
  l_bar: require("../../assets/images/leaderboard/l_bar.png"),
  bg_lboard: require("../../assets/images/leaderboard/bg_lboard.png"),
  bg_tournament: require("../../assets/images/leaderboard/bg_tournament.webp"),
  weekly_tournament: require("../../assets/images/leaderboard/weekly_tournament.png"),
  BRONZE: require("../../assets/images/leaderboard/BRONZE.png"),
  GOLD: require("../../assets/images/leaderboard/GOLD.png"),
  SILVER: require("../../assets/images/leaderboard/SILVER.png"),
  l_your_rank: require("../../assets/images/leaderboard/your_rank.png"),
  bg_rank: require("../../assets/images/leaderboard/bg_rank.png"),
  bg_player_field: require("../../assets/images/leaderboard/bg_player.png"),
  current_rank_badge: require("../../assets/images/leaderboard/current_rank_badge.png"),
  bg_selected_player: require("../../assets/images/leaderboard/bg_selected_player.png"),

  // SHOP FOLDER
  shop_close: require("../../assets/images/shop/shop_close.png"),
  bg_shop_allset: require("../../assets/images/shop/bg_shop_allset.png"),
  bg_subscription_allset: require("../../assets/images/shop/bg_subscription_allset.png"),
  best_value: require("../../assets/images/shop/best_value.png"),
  most_popular: require("../../assets/images/shop/most_popular.png"),

  // BITPACE
  bitpace_error: require("../../assets/images/bitpace/bitpace_error.png"),
  bitpace_success: require("../../assets/images/bitpace/bitpace_success.png"),
  "bitpace-logo": require("../../assets/images/bitpace/bitpace-logo.png"),
  "apcopay-logo": require("../../assets/images/bitpace/apcopay-logo.png"),

  // VIPACCESS
  btn_vip: require("../../assets/images/vipaccess/btn_vip.png"),
  diamond: require("../../assets/images/vipaccess/diamond.png"),
  vip_30days: require("../../assets/images/vipaccess/vip_30days.png"),
  vip_bg: require("../../assets/images/vipaccess/vip_bg.png"),
  vip_text: require("../../assets/images/vipaccess/vip_text.png"),
  vip_dungeon: require("../../assets/images/vipaccess/vip_dungeon.png"),

  // POP UPS
  thank_you: require("../../assets/images/popup/thank_you.png"),
  star: require("../../assets/images/popup/star.png"),
  "dragon-roller": require("../../assets/images/popup/dragon-roller.png"),
  "high-roller": require("../../assets/images/popup/high-roller.png"),
  "hot-deals": require("../../assets/images/popup/hot-deals.png"),
  "starter-pack": require("../../assets/images/popup/starter-pack.png"),
  "tgif": require("../../assets/images/popup/weekly-booster.png"),
  "weekly-booster": require("../../assets/images/popup/weekly-booster.png"),
};

// image preloading
export const imageAssets = Object.keys(images).map((key) =>
  Asset.fromModule(images[key]).downloadAsync()
);

export default images;
