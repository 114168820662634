import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    // SHOP
    v_item_container: {
      height: 200,
      width: "32%",
      alignItems: "center",
      justifyContent: "center",
    },
    marh35: { marginHorizontal: 0 },
    v_center: { alignItems: "center", justifyContent: "space-between" },
    v_top: {
      top: "-3%",
      width: "100%",
      height: "50%",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "center",
    },
    bg_item: {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    i_best_value: { zIndex: 2, top: "20%", left: "-20%", position: "absolute" },
    i_most_popular: { top: "10%", right: "-20%", position: "absolute" },
    i_bonus_gold: { top: "18%", left: "-24%" },
    v_price: {
      width: "90%",
      height: "45%",
      alignItems: "center",
    },

    //SUBSCRIPTION
    i_package: { position: "absolute", top: "-5%", left: "-10%" },
  },
);

export default useStyles;
