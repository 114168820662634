import React, { useRef } from "react";
import { View, ImageBackground, Image, Text } from "react-native";
import Package from "../../../package.json";
import useMetrics from "@src/utils/metrics";
import ProgressBar from "./ProgressBar";
import useStyles from "./styles.css";
import { images } from "@src/theme";

const VERSION = `Version ${Package.version}`;

const Splash = ({ style, label }: any) => {
  const styles = useStyles();
  const progress = useRef<any>(null);
  const { verticalScale } = useMetrics();

  return (
    <ImageBackground
      source={images.gw_bg}
      style={[styles.container, style]}
      resizeMode="stretch"
    >
      <Image
        source={images.fortune_logo}
        style={[
          { width: verticalScale(380), height: verticalScale(380) },
          styles.i_logo,
        ]}
        resizeMode="stretch"
      />
      <Text style={styles.t_loading}>{label || "Loading..."}</Text>

      <View style={styles.v_progress}>
        <Image
          source={images.bg_progress}
          style={styles.i_progress_bg}
          resizeMode="stretch"
        />
        <View style={styles.v_maskprogress}>
          <ProgressBar ref={progress} />
        </View>
      </View>
      <Image
        source={images.gameworkz}
        style={styles.i_gameworkz}
        resizeMode="stretch"
      />
      <Text style={styles.t_version}>{VERSION}</Text>
    </ImageBackground>
  );
};

export default React.memo(Splash);
