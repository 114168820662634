import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../ducksHook";
import {
  authActions, selectErrorMessage,
} from "../slices/auth.slice";

// Types

type ServiceOperators = {
  errorMessage: string;
  onSetErrorMessage: (value: string) => void;
  onResetErrorMessage: () => void;
};

export const useErrorService = (): Readonly<ServiceOperators> => {
  const dispatch = useAppDispatch();

  return {
    errorMessage: useAppSelector(selectErrorMessage),
    onSetErrorMessage: useCallback((value: string) => {
      dispatch(authActions.setErrorMessage(value));
    }, [dispatch]),
    onResetErrorMessage: useCallback(() => {
      dispatch(authActions.setErrorMessage(""));
    }, [dispatch]),
  };
};

export default useErrorService;
