import React, { useContext, useState } from "react";
import { View, Image, Text, Pressable } from "react-native";
import { DimensionContext } from "@src/DimensionProvider";
import { useNavigation } from "@react-navigation/native";
import { useInputHelper } from "utils/inputs-helper";
import { useSignupService } from "@src/ducks/hooks";
import { TextInput01 } from "components/textinput";
import { ImageButton } from "components/button";
import useStyles from "./styles.css";
import { images } from "@src/theme";
import validator from "validator";
import _ from "lodash";

const initialstates = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  password: "",
  cpassword: "",
  referral: "",
  changeOnNextLogon: 0,
  userTypeID: 1,
  gender: "",
  country: "",
  apiKey: "",
};

const EnterFields = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const [error, setError] = useState<any>({});
  const { isLoading, onSignup }= useSignupService();
  const { baseWidth } = useContext(DimensionContext);
  const {state, onDispatch} = useInputHelper(initialstates);
  const onChangeText = (type: string) => (value: string) => {
    onDispatch(type)(value);
    setError({});
  };

  const onSubmit = () => {
    const newErr: any = {};

    if (!state.firstName) {
      newErr.firstName = "Required";
    }
    if (!state.lastName) {
      newErr.lastName = "Required";
    }
    if (!state.emailAddress) {
      newErr.emailAddress = "Required";
    } else if (!validator.isEmail(state.emailAddress.trim())) {
      newErr.emailAddress = "Invalid email format.";
    }
    if (!state.password) {
      newErr.password = "Required";
    }else if (state.password.length < 6) {
      newErr.password = "Password must be at least six characters.";
    }
    if (!state.cpassword) {
      newErr.cpassword = "Required";
    }else if (state.password !== state.cpassword) {
      newErr.cpassword = "Passwords do not match.";
    }

    setError(newErr);

    if (_.isEmpty(newErr)) {
      onSignup(state);
    }
  };

  return (
    <View style={[styles.bodyStyle, { height: baseWidth * 0.3349}]}>
      <Image
        source={images.bg_transparent}
        style={styles.imageBorderStyle}
        resizeMode="stretch"
      />
      <ImageButton
        onPress={() => navigation.goBack()}
        style={styles.btnClose}
        source={images.btn_close}
      />
      <View style={styles.viewEmail}>
        <View style={[styles.viewForgotStyle]}>
          <Image
            resizeMode="contain"
            source={images.create_text}
            style={styles.forgotTextStyle}
          />
        </View>
        <View style={styles.v_name}>
          <TextInput01
            value={state.firstName}
            placeholder="First Name *"
            error={error.firstName}
            containerStyle={styles.fn_style}
            onChangeText={onChangeText("firstName")}
          />
          <TextInput01
            value={state.lastName}
            placeholder="Last Name *"
            error={error.lastName}
            containerStyle={styles.fn_style}
            onChangeText={onChangeText("lastName")}
          />
          <TextInput01
            value={state.emailAddress}
            placeholder="Email *"
            error={error.emailAddress}
            containerStyle={styles.ti_email_style}
            onChangeText={onChangeText("emailAddress")}
          />
        </View>

        <View style={[styles.v_name, styles.v_password]}>
          <TextInput01
            hasPassword
            value={state.password}
            placeholder="Password *"
            error={error.password}
            containerStyle={styles.ti_password}
            onChangeText={onChangeText("password")}
          />
          <TextInput01
            hasPassword
            value={state.cpassword}
            placeholder="Confirm Password *"
            error={error.cpassword}
            containerStyle={styles.ti_password}
            onChangeText={onChangeText("cpassword")}
          />
          <TextInput01
            value={state.referral}
            error={error.referral}
            placeholder="Referral Code"
            containerStyle={styles.ti_referral}
            onChangeText={onChangeText("referral")}
          />
        </View>

        <View style={styles.v_actions}>
          <Pressable onPress={() => navigation.goBack()} style={styles.btn_back}>
            <Image style={styles.i_back}
              source={images.btn_back} resizeMode="stretch" />
            <Text style={[styles.t_back, {fontSize: baseWidth * 0.01}]} >BACK</Text>
          </Pressable>
          <ImageButton
            onPress={onSubmit}
            isLoad={isLoading}
            style={styles.btnSubmit}
            source={images.btn_signup}
          />
        </View>
      </View>
    </View>
  );
};

export default EnterFields;
