import { useWindowDimensions, Dimensions } from "react-native";

const { width: WIDTH, height: HEIGHT } = Dimensions.get("window");

const guidelineBaseWidth = 375;
const guidelineBaseHeight = 812;
export const verticalScale = (size: number) =>
  (HEIGHT / guidelineBaseHeight) * size;
export const horizontalScale = (size: number) =>
  (WIDTH / guidelineBaseWidth) * size;
export const moderateScale = (size: number, factor = 0.5) =>
  size + (horizontalScale(size) - size) * factor;

const useMetrics = () => {
  const { width, height } = useWindowDimensions();

  const horizontalScale2 = (size: number) =>
    (width / guidelineBaseWidth) * size;
  const verticalScale2 = (size: number) =>
    (height / guidelineBaseHeight) * size;
  const moderateScale2 = (size: number, factor = 0.5) =>
    size + (horizontalScale2(size) - size) * factor;
  const moderateScale3 = (size: number, factor = 0.6) =>
    size + (verticalScale2(size) - size) * factor;

  return {
    horizontalScale: horizontalScale2,
    verticalScale: verticalScale2,
    moderateScale: moderateScale2,
    moderateHScale: moderateScale3,
  };
};

export default useMetrics;
