import React, { useState, createContext, useCallback } from "react";
import { useLobbyService } from "@src/ducks/hooks";
import { useFetchPost } from "utils/api/hooks";
import _ from "lodash";

export interface DetailsProps {
  subject: string;
  status: string;
  textValues: string;
}

interface InboxContextValue {
  isLoading: boolean;
  details: DetailsProps;
  onReadDetails: (notificationId: number) => void;
  onDeleteNotif: (value: number) => void;
  onClose: () => void;
}

export const InboxContext = createContext<InboxContextValue>({
  isLoading: false,
  details: {} as DetailsProps,
  onReadDetails: () => {},
  onDeleteNotif: () => {},
  onClose: () => {},
});

interface InboxProviderProps {
  children: React.ReactElement;
}

const InboxProvider = ({ children }: InboxProviderProps) => {
  const [details, setDetails] = useState<any>({});
  const {loading, data, runRequest} = useFetchPost();
  const { onUpdateNotifications } = useLobbyService();

  console.log("data", data);

  const onReadDetails = useCallback(async (notificationId: number) => {
    runRequest(`/api/v1/${notificationId}/notifications?action=READ`,{});
  }, []);

  const onDeleteNotif = useCallback(async (notificationId: number) => {
    runRequest(`/api/v1/${notificationId}/notifications?action=DELETE`,{});
  }, []);

  const onClose = useCallback(() => {
    setDetails({});
  }, []);

  React.useEffect(() => {
    if(!_.isEmpty(data)){
      onUpdateNotifications(data);
      if(data.status === "READ"){
        setDetails(data);
      }
    }
  },[data]);

  return (
    <InboxContext.Provider value={{ isLoading: loading, details, onReadDetails, onDeleteNotif, onClose }} >
      {children}
    </InboxContext.Provider>
  );
};

export default InboxProvider;
