import { useNavigation } from "@react-navigation/native";
import { useProductService } from "@src/ducks/hooks/useProductService";
import { Product } from "@src/ducks/types";
import { images } from "@src/theme";
import { ImageButton } from "components/button";
import React, { useMemo } from "react";
import { Image, View } from "react-native";
import { PROMOTION_POPUPS } from "utils/constants";
import useMetrics from "utils/metrics";
import useStyles from "./styles.css";

interface DetailsProps {
  subject: string;
  status: string;
  textValues: string;
}

const StarterPack = (props: DetailsProps) => {
  const styles = useStyles();
  const { verticalScale } = useMetrics();
  const { getProductByName } = useProductService();
  const navigation = useNavigation<any>();
  
  const holder = useMemo(() => {
    const starterProduct: Product = getProductByName(PROMOTION_POPUPS.STARTER_PACK);
    const package2Product: Product = getProductByName(PROMOTION_POPUPS.PACKAGE_2);

    const Sarter = {
      imagePath: images.bg_starter,
      productId: starterProduct.name,
      amount: starterProduct.price,
      currency: "USD",
      silver: starterProduct.value,
      isShop: true,
      type: "starterpack1",
    };
    
    const Openning = {
      imagePath: images.bg_special,
      productId: package2Product.name,
      amount: package2Product.price,
      currency: "USD",
      silver: package2Product.value,
      isShop: true,
      type: "openingspecial",
    };

    return props?.subject?.includes("Opening special") ? Openning : Sarter;
  }, [props?.subject]);

  const onPress = () => {
    navigation.navigate("PaymentGateway", holder);
  };

  return (
    <View style={[styles.v_body, { width: "98%" }]}>
      <View style={[styles.v_details, { alignItems: "center" }]}>
        <Image
          style={styles.i_pack}
          source={holder.imagePath}
          resizeMode="stretch"
        />
        <ImageButton
          onPress={onPress}
          style={[
            styles.btn_buynow,
            {
              width: verticalScale(200),
              height: verticalScale(90),
            },
          ]}
          source={images.btn_buy_now}
        />
      </View>
    </View>
  );
};

export default StarterPack;
