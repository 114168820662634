import React, { useEffect } from "react";
import Animated, {
  withTiming,
  interpolate,
  Extrapolation,
  useSharedValue,
  useAnimatedStyle,
  withRepeat,
  withDelay,
} from "react-native-reanimated";
import { LinearGradient } from "expo-linear-gradient";

const TranslateX = ({
  style,
  duration,
  outputRange,
  translateY = -110,
}: any) => {
  const animated = useSharedValue(0);

  const animateStyle = useAnimatedStyle(() => {
    const translateX = interpolate(
      animated.value,
      [0, 1],
      outputRange || [-230, 400],
      Extrapolation.CLAMP
    );

    return {
      transform: [
        { translateX },
        { rotate: "45deg" },
        { translateY: translateY },
      ],
    };
  }, [outputRange, translateY]);

  useEffect(() => {
    animated.value = withRepeat(
      withDelay(duration || 4000, withTiming(1, { duration: 2000 })),
      -1,
      false
    );
  }, [duration]);

  return (
    <Animated.View style={[style, animateStyle]}>
      <LinearGradient
        style={{ width: 50, height: "100%" }}
        start={{ x: 1, y: 1 }}
        end={{ x: 1, y: 1 }}
        colors={[
          "#ffffff15",
          "#ffffff40",
          "#ffffff85",
          "#ffffff40",
          "#ffffff15",
        ]}
      />
    </Animated.View>
  );
};

export default TranslateX;
