import moment from "moment";
import _ from "lodash";

const Gender = ["MALE", "FEMALE"];

const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const currentYear = moment().year();

const Days = _.range(1, 32);
const Years = _.rangeRight(1900, currentYear);

const DATES = { Months, Days, Years, Gender };

export default DATES;
