import React from "react";
import { SvgProps } from "react-native-svg";

//SVGs
import BTNDisclaimer from "./btn-disclaimer.svg";
import BTNFaq from "./btn-faq.svg";
import BTNLogout from "./btn-logout.svg";
import BTNPrivacy from "./btn-privacy-policy.svg";
import BTNShare from "./btn-share.svg";
import BTNTermsCondition from "./btn-terms-condition.svg";
import BTNTier from "./btn-tier-level.svg";
import BTNDeactivate from "./btn-deactivate.svg";
import BGSettings from "./bg-settings.svg";
import BGSound from "./bg-sound.svg";
import TDisclaimer from "./t-disclaimer.svg";
import TTierExplanation from "./t-tier-explanation.svg";
import TTierLevelRewards from "./t-tier-level-rewards.svg";
import GScroll from "./g-scroll.svg";
import BGTierExplanation from "./bg-tier-explanation.svg";

interface IconProps extends SvgProps {
  name: "btn-disclaimer" | "btn-faq" | "btn-logout" | "btn-privacy-policy" | "bg-sound" |
    "btn-share" | "btn-terms-condition" | "btn-tier-level" | "bg-settings" | any;
  size?: number;
  stroke?: string;
  fill?: string;
  isActive?: boolean;
}

const SVGSettings = (props: IconProps) => {
  const { name, width, height } = props;
  switch (name) {
  case "btn-disclaimer":
    return <BTNDisclaimer width={width} height={height} {...props} />;
  case "btn-faq":
    return <BTNFaq width={width} height={height} {...props} />;
  case "btn-logout":
    return <BTNLogout width={width} height={height} {...props} />;
  case "btn-privacy-policy":
    return <BTNPrivacy width={width} height={height} {...props} />;
  case "btn-share":
    return <BTNShare width={width} height={height} {...props} />;
  case "btn-terms-condition":
    return <BTNTermsCondition width={width} height={height} {...props} />;
  case "btn-tier-level":
    return <BTNTier width={width} height={height} {...props} />;
  case "btn-deactivate":
    return <BTNDeactivate width={width} height={height} {...props} />;
  case "bg-settings":
    return <BGSettings width={width} height={height} {...props} />;
  case "bg-sound":
    return <BGSound width={width} height={height} {...props} />;
  case "bg-tier-explanation":
    return <BGTierExplanation width={width} height={height} {...props} />;
  case "t-disclaimer":
    return <TDisclaimer width={width} height={height} {...props} />;
  case "t-tier-explanation":
    return <TTierExplanation width={width} height={height} {...props} />;
  case "t-tier-level-rewards":
    return <TTierLevelRewards width={width} height={height} {...props} />;
  case "g-scroll":
    return <GScroll width={width} height={height} {...props} />;
  default:
    return null;
  }
};

export default SVGSettings;
