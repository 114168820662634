import { Asset } from "expo-asset";

const audio: any = {
  whoosh: require("../../assets/audio/bg-sound.mpeg"),
  claim: require("../../assets/audio/claim.wav"),
  click: require("../../assets/audio/click.mpeg"),
  audience_applause: require("../../assets/audio/audience_applause.wav"),
  random_numbers: require("../../assets/audio/random-numbers.mp3"),
  reward_sound: require("../../assets/audio/reward-sound.mp3"),

  // POPUPS
  "dragon-roller": require("../../assets/audio/dragon-roller.mp3"),
  "high-roller": require("../../assets/audio/high-roller.mp3"),
  "hot-deals": require("../../assets/audio/hot-deals.mp3"),
  "starter-pack": require("../../assets/audio/starter-pack.mp3"),
  "weekly-booster": require("../../assets/audio/weekly-booster.mp3"),
  "tgif": require("../../assets/audio/tgif.mp3"),

  // DAILY BONUS
  shuffle_sfx: require("../../assets/audio/daily-reward/shuffle_sfx.mp3"),
  congrats_sfx: require("../../assets/audio/daily-reward/congrats_sfx.mp3"),

  // JADE EGG
  JadeEgg_CurtainIntro: require("../../assets/audio/jade-egg/JadeEgg_CurtainIntro.mp3"),
  JadeEgg_CurtainOpen: require("../../assets/audio/jade-egg/JadeEgg_CurtainOpen.mp3"),
  JadeEggBG_loop: require("../../assets/audio/jade-egg/JadeEggBG_loop.mp3"),
  JadeEgg_CurtainOutro: require("../../assets/audio/jade-egg/JadeEgg_CurtainOutro.mp3"),
  JadeEgg_Cracked: require("../../assets/audio/jade-egg/JadeEgg_Cracked.mp3"),

};

// image preloading
export const audioAssets = Object.keys(audio).map((key) =>
  Asset.fromModule(audio[key]).downloadAsync()
);

export default audio;
