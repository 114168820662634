import React, { useState, useEffect } from "react";
import { selectedAuthUserID } from "@src/ducks/slices/auth.slice";
import { View, Image, TextInput, Text } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useLobbyService } from "@src/ducks/hooks";
import { ImageButton } from "components/button";
import { useFetchPost } from "utils/api/hooks";
import useMetrics from "utils/metrics";
import useStyles from "./styles.css";
import { images } from "@src/theme";
import _ from "lodash";

export default () => {
  const styles = useStyles();
  const {verticalScale} = useMetrics();
  const [name, setName] = useState("");
  const navigation = useNavigation<any>();
  const [error, setError] = useState(false);
  const { onUpdateMissions } = useLobbyService();
  const { onUpdateAvatarTier } = useLobbyService();
  const {loading, runRequest, data} = useFetchPost();
  const userId = useAppSelector(selectedAuthUserID);
  const onDone = () => {
    if (name) {
      const tarea_regex = /(http:|https:|ws:|wss:)/;
      if (tarea_regex.test(name.toLowerCase())) {
        setError(true);
      } else {
        runRequest(`/api/v1/user/${userId}/changeusername`, { username: name });
      }
    } else {
      navigation.goBack();
    }
  };

  useEffect(() => {
    if(!_.isEmpty(data)){
      onUpdateAvatarTier({displayName: name});
      onUpdateMissions("displayname");
      navigation.goBack();
    }
  }, [data]);

  useEffect(() => {
    setError(false);
  }, [name]);

  return (
    <View style={styles.container}>
      <View style={[styles.center_view, { height: verticalScale(150)}]}>
        <Image
          style={styles.i_background}
          source={images.bg_edit_name}
          resizeMode="stretch"
        />
        <View style={[styles.v_textwrap, error && styles.v_textwrap_error]}>
          <TextInput
            value={name}
            maxLength={15}
            onChangeText={setName}
            placeholder="Type name here"
            style={styles.ti_username}
            placeholderTextColor={"white"}
          />
          {error && <Text style={styles.t_error}>Invalid</Text>}
        </View>
        <ImageButton
          onPress={onDone}
          isLoad={loading}
          style={styles.btn_close}
          source={images.btn_done}
        />
      </View>
    </View>
  );
};
