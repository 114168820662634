import React from "react";
import { AuthContext } from "@src/AuthProvider";
import { Image, Pressable, StyleSheet, Animated, ActivityIndicator } from "react-native";

const AnimatedButton = Animated.createAnimatedComponent(Pressable);

const ImageButton = (props: any) => {
  const { handleClick } = React.useContext(AuthContext);
  const { style, source, onPress, disabled, scaleSize } = props;
  const animated = React.useRef(new Animated.Value(1)).current;

  const onPressIn = () => {
    Animated.timing(animated, {
      toValue: scaleSize || 0.9,
      duration: 100,
      useNativeDriver: false,
    }).start();
  };

  const onPressOut = () => {
    Animated.timing(animated, {
      toValue: 1,
      duration: 100,
      useNativeDriver: false,
    }).start();
  };

  const onClick = () => {
    onPress();
    handleClick();
  };

  return (
    <AnimatedButton
      onPress={onClick}
      disabled={disabled}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      style={[styles.buttonStyle, style, { transform: [{ scale: animated }] }]}
    >
      {props.isLoad ? <ActivityIndicator color="white"/> : <Image
        source={source}
        resizeMode="stretch"
        style={styles.imageButtonStyle}
      />}
    </AnimatedButton>
  );
};

const styles = StyleSheet.create({
  buttonStyle: {
    width: "40%",
    height: 70,
    alignItems: "center",
    justifyContent: "center"
  },
  imageButtonStyle: {
    width: "100%",
    height: "100%",
  },
});

export default ImageButton;
