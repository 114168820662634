import React from "react";
import { View, Image } from "react-native";
import { selectedMissionCompleted } from "@src/ducks/slices/lobby.slice";
import { useSettingsService, useLobbyService } from "@src/ducks/hooks";
import { DimensionContext } from "@src/DimensionProvider";
import { useNavigation } from "@react-navigation/native";
import { useAppSelector } from "@src/ducks/ducksHook";
import { ImageButton } from "components/button";
import { Scale } from "utils/animation";
import useStyles from "./styles.css";
import { images } from "@src/theme";

const PopupKey = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { onChangeProfile } = useSettingsService();
  const { baseWidth } = React.useContext(DimensionContext);
  const { onChangeScreen, onUpdateMissions } = useLobbyService();
  const taskcompleted = useAppSelector(selectedMissionCompleted);

  const onSeeMisison = () => {
    onChangeProfile(false);
    onUpdateMissions("clearkey");
    onChangeScreen({ screen: "mission" });
    navigation.goBack();
  };

  return (
    <View style={styles.container}>
      <Scale style={styles.container}>
        <View style={[styles.center_view, 
          { width: baseWidth * 0.6103, height: baseWidth * 0.3442 },
        ]}>
          <Image
            style={styles.i_background}
            source={images.bg_popup}
            resizeMode="stretch"
          />
          <View style={styles.v_body}>
            <View style={styles.v_header}>
              <Image
                style={styles.i_title}
                source={
                  taskcompleted
                    ? images.mission_completed
                    : images.task_completed
                }
                resizeMode="stretch"
              />
            </View>
            <View style={styles.v_middle_details}>
              <View style={styles.v_right_details}>
                <Image
                  style={[
                    styles.i_mission_key,
                    !taskcompleted && styles.i_mission_1key,
                  ]}
                  source={
                    taskcompleted ? images.mission_3keys : images.mission_1key
                  }
                  resizeMode="stretch"
                />
              </View>
              <View style={styles.v_left_details}>
                <View style={styles.v_textwrap}>
                  <Image
                    style={styles.i_notes}
                    source={
                      taskcompleted ? images.note_3_key : images.note_1_key
                    }
                    resizeMode="stretch"
                  />
                </View>
                <ImageButton
                  onPress={onSeeMisison}
                  style={[
                    styles.btn_ok,
                    !taskcompleted && styles.btn_see_mission,
                  ]}
                  source={
                    taskcompleted ? images.btn_open_vault : images.btn_mission
                  }
                />
              </View>
            </View>
          </View>
        </View>
      </Scale>
    </View>
  );
};

export default PopupKey;
