import React, { useMemo, useState, useEffect, useContext } from "react";
import { DimensionContext } from "@src/DimensionProvider";
import { ImageButton } from "components/button";
import { View, Image } from "react-native";
import { InboxContext } from "../provider";
import CheapTuesday from "./cheaptuesday";
import { MInbox } from "utils/mockdata";
import StarterPack from "./starterpack";
import useStyles from "./styles.css";
import { images } from "@src/theme";
import MoreCoins from "./morecoins";
import TopPlace from "./top-place";
import Default from "./default";
import Games from "./games";
import _ from "lodash";

const Details = () => {
  const styles = useStyles();
  const { details } = useContext(InboxContext);
  const [visible, setVisible] = useState(false);
  const { baseWidth } = React.useContext(DimensionContext);

  const onClose = () => setVisible(false);

  const RenderItem = useMemo(() => {
    const subject = (details?.subject ?? "").toLowerCase();
    if (
      subject.includes("opening special") ||
      subject.includes("starter pack")
    ) {
      return <StarterPack {...details} />;
    } else if (subject.includes("cheep tuesday")) {
      return <CheapTuesday />;
    } else if (subject.includes("you will love this slot")) {
      return <Games {...MInbox[0]} />;
    } else if (subject.includes("try your luck")) {
      return <Games {...MInbox[1]} />;
    } else if (subject.includes("need more coins")) {
      return <MoreCoins />;
    } else if (
      subject.includes("place first") ||
      subject.includes("place second") ||
      subject.includes("place third")
    ) {
      return <TopPlace {...details} />;
    } else {
      // return <Games {...MInbox[1]} />;
      return <Default {...details} />;
    }
  }, [details]);

  useEffect(() => {
    if (!_.isEmpty(details)) {
      setVisible(true);
    }

    return () => {
      setVisible(false);
    };
  }, [details]);

  if (!visible) {
    return null;
  }

  return (
    <View style={styles.container}>
      <View
        style={[
          styles.center_view,
          { width: baseWidth * 0.5611, height: baseWidth * 0.3076 },
        ]}
      >
        <Image
          style={styles.i_background}
          source={images.bg_inbox}
          resizeMode="stretch"
        />
        <ImageButton
          onPress={onClose}
          style={styles.btn_close}
          source={images.btn_close}
        />
        <View style={styles.v_center}>
          <View style={styles.v_inbox}>
            <Image
              style={styles.i_inbox}
              source={images.logo_inbox}
              resizeMode="stretch"
            />
            <Image
              style={styles.i_message}
              source={images.ic_message}
              resizeMode="stretch"
            />
          </View>
          {RenderItem}
        </View>
      </View>
    </View>
  );
};

export default Details;
