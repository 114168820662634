import React, { useMemo } from "react";
import { View, Image, Text, ScrollView } from "react-native";
import useStyles from "./styles.css";
import { images } from "@src/theme";

const getImage = (subject: string) => {
  const lower = subject.toLowerCase();

  if (lower.includes("fortune")) {
    return images.ic_welcome;
  } else if (lower.includes("congratulations")) {
    return images.ic_congrats;
  } else if (lower.includes("bonus")) {
    return images.ic_daily;
  } else if (lower.includes("play")) {
    return images.ic_elves;
  } else if (lower.includes("version")) {
    return images.ic_version;
  } else if (lower.includes("games")) {
    return images.ic_more_games;
  } else if (lower.includes("first")) {
    return images.ic_trophy_gold;
  } else if (lower.includes("second")) {
    return images.ic_trophy_silver;
  } else if (lower.includes("third")) {
    return images.ic_trophy_bronze;
  } else {
    return null;
  }
};

interface DetailsProps {
  subject: string;
  status: string;
  textValues: string;
}

const Details = (props: DetailsProps) => {
  const styles = useStyles();
  const icon = useMemo(() => getImage(props.subject || ""), [props.subject]);

  return (
    <View style={styles.v_body}>
      <View style={[styles.default_details]}>
        <View style={styles.v_info}>
          <ScrollView showsVerticalScrollIndicator={false}>
            <Text style={styles.t_subject}>{props.subject}</Text>
            <Text style={styles.t_details}>
              {props.textValues}
            </Text>
          </ScrollView>
        </View>
        <View style={[styles.v_icon]}>
          {icon && (
            <Image style={styles.i_icon} source={icon} resizeMode="stretch" />
          )}
        </View>
      </View>
    </View>
  );
};

export default Details;
