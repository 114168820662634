import React, { useMemo } from "react";
import { View, Image, Text } from "react-native";
import useMetrics from "utils/metrics";
import useStyles from "./styles.css";
import { images } from "@src/theme";

interface DetailsProps {
  subject: string;
}

const getTopLabel = (subject: string) => {
  const lower = subject.toLowerCase();

  if (lower.includes("first")) {
    return { label: "FIRST", amount: "1,000,000" };
  } else if (lower.includes("second")) {
    return { label: "SECOND", amount: "500,000" };
  } else if (lower.includes("third")) {
    return { label: "THIRD", amount: "200,000" };
  }
};

const TopPlace = (props: DetailsProps) => {
  const styles = useStyles();
  const { moderateScale } = useMetrics();
  const spot: any = useMemo(
    () => getTopLabel(props.subject || ""),
    [props.subject]
  );

  return (
    <View style={styles.v_top_body}>
      <View style={[styles.v_top_player]}>
        <Image
          style={styles.i_trophy}
          source={images.ic_trophy}
          resizeMode="stretch"
        />
        <View style={styles.top_info}>
          <Text style={[styles.t_congrats, { fontSize: moderateScale(20) }]}>
            CONGRATULATIONS!
          </Text>
          <Text style={[styles.t_place, { fontSize: moderateScale(15) }]}>
            You’ve placed <Text style={styles.t_place_color}>{spot.label}</Text>{" "}
            in this week’s tournament
          </Text>
        </View>
        <View style={styles.top_silver}>
          <Image
            style={styles.i_icon}
            source={images.ic_silver}
            resizeMode="stretch"
          />
          <Text
            style={[styles.t_silver_amount, { fontSize: moderateScale(16) }]}
          >
            {spot.amount}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default TopPlace;
