const Coins = [
  {
    duration: 300,
    transX: [0, 100],
  },
  {
    duration: 400,
    transX: [0, 100],
  },
  {
    duration: 500,
    transX: [0, 100],
  },
  {
    duration: 600,
    transX: [0, 100],
  },
  {
    duration: 700,
    transX: [0, 100],
  },
  {
    duration: 800,
    transX: [0, 100],
  },
  {
    duration: 900,
  },
  {
    duration: 1000,
    transX: [0, 100],
  },
];

export default Coins;
