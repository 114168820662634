import React, { useRef, useEffect } from 'react';
import { Animated, StyleSheet } from 'react-native';

const ScaleAnimated = ({ children, style, duration, scaleSize }: any) => {
  const animated = useRef(new Animated.Value(0)).current;
  const scale = animated.interpolate({
    inputRange: [0, 1],
    outputRange: [scaleSize || 0.4, 1],
    extrapolate: 'clamp',
  });

  useEffect(() => {
    Animated.timing(animated, {
      toValue: 1,
      duration: duration || 300,
      useNativeDriver: false,
    }).start();
  }, []);

  return (
    <Animated.View
      style={[style, styles.container, { transform: [{ scale }] }]}
    >
      {children}
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'transparent',
  },
});

export default ScaleAnimated;
