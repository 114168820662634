const Shop = [
  {
    amount: 2.99,
    productId: "gwz_2.99_2m_silv",
    currency: "USD",
    silver: 2000000,
    priceIcon: "price_1m",
    gold: 0,
    chestIcon: "chest1",
    isShop: true,
  },
  {
    amount: 5.99,
    productId: "gwz_5.99_6m_silv",
    priceIcon: "price_4m",
    currency: "USD",
    silver: 6000000,
    gold: 3,
    chestIcon: "chest2",
    isShop: true,
    bonusGold: "bonus_gold10",
    isMostPopular: true,
  },
  {
    amount: 10.99,
    productId: "gwz_10.99_10m_silv",
    priceIcon: "price_6m",
    currency: "USD",
    silver: 10000000,
    gold: 11,
    chestIcon: "chest3",
    bonusGold: "bonus_gold14",
    isShop: true,
  },
  {
    amount: 19.99,
    productId: "gwz_19.99_20m_silv",
    priceIcon: "price_9m",
    currency: "USD",
    silver: 20000000,
    gold: 20,
    chestIcon: "chest3",
    bonusGold: "bonus_gold27",
    isBestValue: true,
    isShop: true,
  },
  {
    amount: 49.99,
    productId: "gwz_49.99_50m_silv",
    priceIcon: "price_15m",
    currency: "USD",
    silver: 50000000,
    gold: 50,
    chestIcon: "chest3",
    bonusGold: "bonus_gold50",
    isShop: true,
  },
  {
    amount: 74.99,
    productId: "gwz_74.99_75m_silv",
    priceIcon: "price_25m",
    currency: "USD",
    silver: 75000000,
    gold: 75,
    chestIcon: "chest4",
    bonusGold: "bonus_gold75",
    isShop: true,
  },
];

const SubSciption = [
  {
    name: "PACKAGE 1",
    amount: 21.99,
    currency: "USD",
    silver: 2000000,
    gold: 22,
    isSubscription: true,
    productId: "gwz_21.99_14d_package_1",
    packIcon: "pack1",
    chestIcon: "pack_chest1",
    silverIcon: "pack_silver1",
    bonusIcon: "bonus_gold22",
    priceIcon: "price_pack1",
  },
  {
    name: "PACKAGE 2",
    amount: 54.99,
    currency: "USD",
    silver: 5000000,
    gold: 55,
    isSubscription: true,
    productId: "gwz_54.99_14d_package_2",
    chestIcon: "pack_chest2",
    priceIcon: "price_pack2",
  },
];

const SubscriptionProduct: any = ["gwz_21.99_14d_package_1", "gwz_54.99_14d_package_2"];

export default {
  Shop,
  SubSciption,
  SubscriptionProduct,
};
