import React from "react";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { DimensionContext } from "@src/DimensionProvider";
import { useNavigation } from "@react-navigation/native";
import { useSignupService } from "@src/ducks/hooks";
import { View, Image, Text } from "react-native";
import { ImageButton } from "components/button";
import useStyles from "./styles.css";
import { images } from "@src/theme";

const SignupCompleted = () => {
  const styles = useStyles();
  const sendDataToGTM = useGTMDispatch();
  const navigation = useNavigation<any>();
  const { onSignupReset } = useSignupService();
  const { baseWidth } = React.useContext(DimensionContext);

  const onPress = () => {
    navigation.navigate("SignIn");
    onSignupReset();
  };

  React.useEffect(() => {
    sendDataToGTM({
      event: "signup_completed",
      value: "success",
    });
  }, []);

  return (
    <View style={[styles.v_completed, { height: baseWidth * 0.3349}]}>
      <Image
        source={images.bg_transparent}
        style={styles.imageBorderStyle}
        resizeMode="stretch"
      />

      <View style={styles.viewEmail}>
        <View style={[styles.v_congrats]}>
          <Image
            resizeMode="contain"
            source={images.congrats_text}
            style={styles.forgotTextStyle}
          />
        </View>
        <Text style={[styles.t_thankyou, {fontSize: baseWidth * 0.018}]}>Thank you for signing up.</Text>
        <View style={styles.height20} />
        <Text style={[styles.t_thankyou, {fontSize: baseWidth * 0.018}]}>
          You may now enjoy playing Fortune 8’s
        </Text>
        <Text style={[styles.t_thankyou, {fontSize: baseWidth * 0.018}]}>
          fun and exciting collection of games!
        </Text>

        <ImageButton
          onPress={onPress}
          style={styles.btn_login}
          source={images.btn_login_again}
        />
      </View>
    </View>
  );
};

export default SignupCompleted;
