import { images } from "@src/theme";

const Ads = [
  // {
  //   enrollmentDate: '2023-08-02T02:29:17.000+00:00',
  //   lastModifiedDate: '2023-08-02T02:29:17.000+00:00',
  //   gameName: 'Sword Master',
  //   gameTypeID: 7,
  //   packageFilePath: 'https://res-swm.2cy.games/sao2/beta/play_pc.html',
  //   isPremium: 0,
  //   isVIP: 0,
  //   isActive: 1,
  //   code: 2000,
  //   tierID: 1,
  //   imagePath: images.swordplay,
  //   id: 202,
  // },
  {
    imagePath: images.OpeningSpecial,
    productId: "gwz_5.99_6m_silv",
    amount: 5.99,
    currency: "USD",
    silver: 6000000,
    isShop: true,
    type: "openingspecial",
  },
  {
    enrollmentDate: "2022-02-14T05:22:59.000+00:00",
    lastModifiedDate: "2022-02-14T05:22:59.000+00:00",
    gameName: "Pachin Girl",
    gameTypeID: 3,
    packageFilePath: "instant\\html5\\evoplay\\pachingirl",
    isPremium: 0,
    isVIP: 1,
    isActive: 1,
    code: 5743,
    tierID: 4,
    imagePath: images.PachinGirl,
    id: 103,
  },
  {
    enrollmentDate: "2022-02-14T05:22:58.000+00:00",
    lastModifiedDate: "2022-02-14T05:22:58.000+00:00",
    gameName: "Dungeon Immortal Evil",
    gameTypeID: 1,
    packageFilePath: "fullstate\\html5\\evoplay\\dungeon",
    isPremium: 0,
    isVIP: 1,
    isActive: 1,
    code: 5452,
    tierID: 4,
    imagePath: images.DungeonImmortalEvil,
    id: 23,
  },
  {
    enrollmentDate: "2022-02-14T05:07:58.000+00:00",
    lastModifiedDate: "2022-02-14T05:07:58.000+00:00",
    gameName: "Naughty Girls Cabaret",
    gameTypeID: 1,
    packageFilePath: "fullstate\\html5\\evoplay\\cabaret",
    isPremium: 0,
    isVIP: 0,
    isActive: 1,
    code: 104,
    tierID: 3,
    imagePath: images.NaughtyGirlsCabaret,
    id: 22,
  },
  {
    enrollmentDate: "2022-02-14T05:22:59.000+00:00",
    lastModifiedDate: "2022-02-14T05:22:59.000+00:00",
    gameName: "Tree of Light",
    gameTypeID: 1,
    packageFilePath: "fullstate\\html5\\evoplay\\treeoflight",
    isPremium: 0,
    isVIP: 1,
    isActive: 1,
    code: 5742,
    tierID: 4,
    imagePath: images.TreeOfLight,
    id: 67,
  },
  {
    enrollmentDate: "2022-02-14T05:45:45.000+00:00",
    lastModifiedDate: "2022-02-14T05:45:45.000+00:00",
    gameName: "Nuke World",
    gameTypeID: 1,
    packageFilePath: "fullstate\\html5\\evoplay\\nukeworld",
    isPremium: 0,
    isVIP: 0,
    isActive: 1,
    code: 5398,
    tierID: 1,
    imagePath: images.NukeWorld,
    id: 146,
  },
];

const OpeningSpecial = {
  imagePath: images.OpeningSpecial,
  productId: "gwz_5.99_6m_silv",
  amount: 5.99,
  currency: "USD",
  silver: 6000000,
  isShop: true,
  type: "openingspecial",
};

export default Ads;
