import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../ducksHook";
import {
  signupActions, selectedSigningIn
} from "../slices/signup.slice";

// Types
import { SignupInput } from "../types";

type ServiceOperators = {
  isLoading: boolean;
  onSignup: (params: SignupInput) => void;
  onSignupReset: () => void;
};

export const useSignupService = (): Readonly<ServiceOperators> => {
  const dispatch = useAppDispatch();

  return {
    isLoading: useAppSelector(selectedSigningIn),
    onSignup: useCallback(
      (params: SignupInput) => {
        dispatch(signupActions.signupRequest(params));
      },
      [dispatch]
    ),
    onSignupReset: useCallback(
      () => dispatch(signupActions.signupSuccess(false)),
      [dispatch]
    ),
  };
};

export default useSignupService;
