import React, { useState, useEffect } from "react";
import {
  View,
  Image,
  Text,
  ImageBackground,
  TouchableOpacity,
} from "react-native";
import { DimensionContext } from "@src/DimensionProvider";
import { useNavigation } from "@react-navigation/native";
import { TextInput01 } from "components/textinput";
import { useAuthService } from "@src/ducks/hooks";
import { Loading01 } from "components/loading";
import useStyles from "./styles.css";
import { images } from "@src/theme";
import Footer from "./Footer";
import _ from "lodash";

interface ELoginProps {
  email?: string;
  password?: string;
}

const Login = () => {
  const styles = useStyles();
  const [email, setEmail] = useState("");
  const navigation = useNavigation<any>();
  const [password, setPassword] = useState("");
  const [error, setError] = useState<ELoginProps>({});
  const { baseWidth } = React.useContext(DimensionContext);
  const { onSignin, isLoading, onResetLoading } = useAuthService();

  const onLogin = () => {
    const Err: ELoginProps = {};
    if (!email) {
      Err.email = "Required";
    } else if (!password) {
      Err.password = "Required";
    }

    setError(Err);

    if (_.isEmpty(Err)) {
      onSignin({username: email, password});
    }
  };

  useEffect(() => {
    onResetLoading();
  }, []);
  
  useEffect(() => {
    setError({});
  }, [email, password]);

  return (
    <ImageBackground
      source={images.gw_bg}
      style={[styles.container]}
      resizeMode="stretch"
    >
      <View style={[styles.bodyStyle]}>
        <Image
          source={images.bg_transparent}
          style={styles.imageBorderStyle}
          resizeMode="stretch"
        />
        <View style={styles.viewLoginStyle}>
          <Image
            source={images.login_text}
            style={styles.loginTextStyle}
            resizeMode="contain"
          />
        </View>
        <View style={styles.v_inputs}>
          <TextInput01
            value={email}
            placeholder="Email"
            error={error?.email}
            onChangeText={setEmail}
            disableFullscreenUI={true}
            keyboardType="email-address"
            inputStyle={styles.textAlign}
            containerStyle={[styles.ti_email]}
          />
          <TextInput01
            hasPassword
            value={password}
            placeholder="Password"
            secureTextEntry={true}
            error={error?.password}
            disableFullscreenUI={true}
            onChangeText={setPassword}
            inputStyle={styles.textAlign}
            containerStyle={[styles.ti_email]}
          />
          <View style={styles.forgotWrapper}>
            <TouchableOpacity
              onPress={() => navigation.navigate("SignUp")}
              style={styles.btnForgot} >
              <Text style={[styles.txtSignUp, { fontSize: baseWidth * 0.011}]}>Don’t have an account? SIGN UP HERE</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => navigation.navigate("Forgot")}
              style={styles.btnForgot} >
              <Text style={[styles.txtForgot, { fontSize: baseWidth * 0.011}]}>forgot password?</Text>
            </TouchableOpacity>
          </View>
        </View>
        <Footer onPress={onLogin} baseWidth={baseWidth} />
        <View style={styles.bottomHeight} />
        <Loading01 isLoading={isLoading} />
      </View>
    </ImageBackground>
  );
};

export default Login;
