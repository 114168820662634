import React, { useRef, useEffect } from 'react';
import { Animated, Easing } from 'react-native';

const Bounce = ({ children, style, animate }: any) => {
  const animated = useRef(new Animated.Value(0)).current;

  const handleAnimation = () => {
    const sequence = [
      Animated.timing(animated, {
        toValue: 0,
        duration: 400,
        easing: Easing.linear,
        useNativeDriver: false,
      }),
      // rotate in other direction, to minimum value (= twice the duration of above)
      Animated.timing(animated, {
        toValue: 1,
        duration: 400,
        easing: Easing.linear,
        useNativeDriver: false,
      }),
      // return to begin position
    ];

    const sequence2 = [
      Animated.timing(animated, {
        toValue: 2,
        duration: 200,
        easing: Easing.linear,
        useNativeDriver: false,
      }),
      Animated.timing(animated, {
        toValue: 1,
        duration: 200,
        easing: Easing.linear,
        useNativeDriver: false,
      }),
    ];

    // A loop is needed for continuous animation
    Animated.sequence([
      // Animated.delay(2000),
      // start rotation in one direction (only half the time is needed)
      ...sequence,
      ...sequence2,
    ]).start();
  };

  const scale = animated.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1],
    extrapolate: 'clamp',
  });

  const translateY = animated.interpolate({
    inputRange: [1, 2],
    outputRange: [0, -10],
    extrapolate: 'clamp',
  });

  useEffect(() => {
    if (animate) {
      handleAnimation();
    }
  }, [animate]);

  return (
    <Animated.View
      style={[
        style,
        {
          transform: [
            {
              scale,
            },
            { translateY },
          ],
        },
      ]}
    >
      {children}
    </Animated.View>
  );
};

export default Bounce;
