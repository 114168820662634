import App from "@src/App";
import { LogBox } from "react-native";

LogBox.ignoreLogs([
  "StyleSheet.compose",
  "Animated: `useNativeDriver`",
  "setNativeProps is deprecated",
]);

export default App;
