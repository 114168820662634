import React from "react";
import { View, StyleSheet } from "react-native";
import InboxProvider from "./provider";
import { Scale } from "utils/animation";
import { colors } from "@src/theme";
import InboxScreen from "./list";
import Details from "./details";

const Inbox = () => {
  return (
    <InboxProvider>
      <View style={styles.container}>
        <Scale style={styles.container}>
          <InboxScreen />
          <Details />
        </Scale>
      </View>
    </InboxProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.translucent,
  },
});

export default React.memo(Inbox);
