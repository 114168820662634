import React, { useMemo, useContext } from "react";
import { View, Image, Text } from "react-native";
import { ImageButton } from "components/button";
import { InboxContext } from "../provider";
import useStyles from "./styles.css";
import { images } from "@src/theme";

const getImage = (subject: string) => {
  if (subject.toLowerCase().includes("fortune")) {
    return images.ic_fortune;
  } else if (subject.toLowerCase().includes("bonus")) {
    return images.ic_bonus;
  } else if (subject.toLowerCase().includes("play")) {
    return images.ic_play;
  } else if (subject.toLowerCase().includes("version")) {
    return images.ic_version;
  } else if (subject.toLowerCase().includes("games")) {
    return images.ic_more_games;
  } else if (subject.toLowerCase().includes("first")) {
    return images.ic_trophy_gold;
  } else if (subject.toLowerCase().includes("second")) {
    return images.ic_trophy_silver;
  } else if (subject.toLowerCase().includes("third")) {
    return images.ic_trophy_bronze;
  } else if (
    subject.toLowerCase().includes("opening special") ||
    subject.toLowerCase().includes("cheap tuesday") ||
    subject.toLowerCase().includes("cheep tuesday") ||
    subject.toLowerCase().includes("starter pack")
  ) {
    return images.ic_sale;
  } else if (subject.toLowerCase().includes("try your luck")) {
    return images.ic_pachin;
  } else if (subject.toLowerCase().includes("you will love this slot")) {
    return images.ic_candy;
  } else if (subject.toLowerCase().includes("need more coins")) {
    return images.ic_shop;
  } else {
    return images.ic_fortune;
  }
};

const InboxItem = ({ height, item }: any) => {
  const styles = useStyles();
  const icon = useMemo(() => getImage(item.subject), [item.subject]);
  const { onReadDetails, onDeleteNotif } = useContext(InboxContext);
  const onRead = () => onReadDetails(item.id);
  const onDelete = () => onDeleteNotif(item.id);

  return (
    <View
      style={[
        styles.v_item,
        {
          height: height * 0.2,
          marginTop: height * 0.1,
        },
      ]}
    >
      {item.status === "READ" ? null : (
        <Image
          style={styles.i_bg_message}
          source={images.bg_message}
          resizeMode="stretch"
        />
      )}
      <Image style={styles.i_item_icon} source={icon} resizeMode="stretch" />
      <View style={[styles.v_subject, { height: height * 0.17 }]}>
        <Text numberOfLines={2} style={styles.t_notif}> 
          {item.subject}
        </Text>
      </View>
     
      <ImageButton
        onPress={onRead}
        style={styles.btn_read}
        source={images.btn_read}
      />
      <ImageButton
        onPress={onDelete}
        style={styles.btn_notif_close}
        source={images.btn_close}
      />
    </View>
  );
};

export default React.memo(InboxItem);
