import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import { verticalScale } from "react-native-size-matters";
import { StyleSheet } from "react-native";
import { colors } from "@src/theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    bodyStyle: {
      width: "58%",
      height: verticalScale(260),
      alignItems: "center",
      justifyContent: "center",
    },
    imageBorderStyle: {
      width: "100%",
      height: "100%",
      ...StyleSheet.absoluteFillObject,
    },
    v_center: {
      width: "92.5%",
      height: "90%",
      marginBottom: "2%",
      marginRight: "2%",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
    },
    v_center_reset: {
      width: "92.7%",
      height: "90%",
      left: "2.8%",
      top: "4%",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
    },
    bottomHeight: {
      width: 100,
      height: "18%",
    },
    header: {
      zIndex: 1,
      fontSize: 30,
      paddingTop: 48,
      color: "#6C0B7B",
      marginBottom: 40,
      fontFamily: "Roboto_Medium",
    },

    // ENTER EMAIL COMPONENT STYLE
    viewForgotStyle: {
      width: "70%",
      height: "21%",
      alignItems: "center",
      justifyContent: "center",
    },
    forgotTextStyle: { width: "100%", height: 60 },
    viewEmail: {
      flex: 1,
      right: "1%",
      width: "93%",
      alignItems: "center",
      justifyContent: "center",
    },
    txtEmailNote: {
      fontSize: 16,
      textAlign: "center",
      color: colors.white,
      fontFamily: "Roboto_Regular",
    },
    inputEmailStyle: { marginTop: 30, height: "15%" },
    height20: { height: 20 },
    btnSubmit: { height: 60, width: 140, marginLeft: 10 },
    btnClose: {
      position: "absolute",
      zIndex: 1,
      top: "20%",
      right: "-35%",
    },
    imageCloseStyle: { height: 30, width: 30 },
    textAlign: { textAlign: "center", paddingLeft: 0, fontSize: 16 },

    // RESET PASSWORD
    ti_reset: { marginTop: 10, height: 50, marginBottom: 10 },
    btn_reset_password: { height: "16%", width: "30%", marginLeft: 10 },

    // SUCCESS
    i_success: { width: "90%", height: "40%" },
    v_logo: {
      width: "22%",
      height: "30%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_fortune_logo: {
      width: "100%",
      height: "100%",
    },
  },
  {
    [DEVICE_SIZES.MD]: {
      ti_reset: {
        marginTop: 10,
        height: 38,
      },
      v_logo: {
        width: 100,
        height: "28%",
      },
      height20: {
        height: 10,
      },
      txtEmailNote: { fontSize: 15 },
      btnSubmit: {
        height: 50,
        marginBottom: "1%",
      },
      btn_reset_password: {
        height: "18%",
      },
    },
    [DEVICE_SIZES.SM]: {
      ti_reset: {
        marginTop: 10,
        height: 30,
      },
      v_logo: {
        width: 80,
        height: "26%",
      },
      txtEmailNote: {
        fontSize: 13,
      },
      height20: {
        height: 10,
      },
      btnSubmit: {
        width: 100,
        height: 45,
        marginBottom: "1%",
      },
      btn_reset_password: {
        height: "18%",
      },
    },
    [DEVICE_SIZES.XS]: {
      ti_reset: {
        marginTop: 10,
        height: 30,
      },
      v_logo: {
        width: 80,
        height: "26%",
      },
      txtEmailNote: {
        fontSize: 13,
      },
      height20: {
        height: 10,
      },
      btnSubmit: {
        width: 100,
        height: 45,
      },
    },
  }
);

export default useStyles;
