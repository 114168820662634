import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    // ITEM COMPONENTS
    v_item_container: {
      width: 60,
      height: 60,
      borderRadius: 30,
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
    },
    v_share: {
      width: 67,
      height: 32,
    },
    imageStyle: { width: "100%", height: "100%" },
  },
  {
    [DEVICE_SIZES.MD]: {
      v_item_container: {
        width: 40,
        height: 40,
        borderRadius: 20,
      },
      v_share: {
        width: 43,
        height: 22,
      },
    },
    [DEVICE_SIZES.SM]: {
      v_item_container: {
        width: 30,
        height: 30,
        borderRadius: 15,
      },
      v_share: {
        width: 33,
        height: 17,
      },
    },
    [DEVICE_SIZES.XS]: {
      v_item_container: {
        width: 30,
        height: 30,
        borderRadius: 15,
      },
      v_share: {
        width: 33,
        height: 17,
      },
    },
  }
);

export default useStyles;
