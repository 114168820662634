import React, { useCallback } from "react";
import Animated, { Extrapolate, interpolate, useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";
import { StyleSheet, Image } from "react-native";
import { ImageButton02 } from "components/button";
import CoinSelection from "./coin-selection";
import { images, colors } from "@src/theme";
import useMetrics from "utils/metrics";
import useConfig from "./useConfig";
import Actions from "./Actions";

function ActionButton() {
  const { SIZE, ITEM_SIZE } = useConfig();
  const actionHeight = useSharedValue(0);
  const { verticalScale } = useMetrics();

  const onAction = useCallback(() => {
    const value = actionHeight.value === 0 ? (ITEM_SIZE + 11) * 4 : 0;
    actionHeight.value = withTiming(value);
  }, [actionHeight.value, ITEM_SIZE, SIZE]);

  const HStyle = useAnimatedStyle(() => {
    return {
      height: actionHeight.value,
    };
  }, []);

  const OpacityStyle = useAnimatedStyle(() => {
    const opacity = interpolate(
      actionHeight.value,
      [0, ((ITEM_SIZE + 11) * 5) * 0.9, (ITEM_SIZE + 11) * 5],
      [0, 0, 1],
      Extrapolate.CLAMP
    );
    return {
      position: "absolute", left: 10, zIndex: 2,
      top: (ITEM_SIZE + 11) * 5 + verticalScale(20),
      opacity,
    };
  }, []);

  return (
    <>
      <Animated.View
        style={[
          styles.v_circle,
          {
            width: SIZE,
            minHeight: SIZE,
            borderRadius: SIZE / 2,
            top: verticalScale(30)
          },
        ]}
      >
        <ImageButton02
          onPress={onAction}
          style={[
            styles.btn_float,
            {
              width: SIZE - 10,
              height: SIZE - 10,
              borderRadius: SIZE - 10 / 2,
            },
          ]}
        >
          <Image
            style={{ width: SIZE - 30, height: SIZE - 30 }}
            source={images.fortune8}
            resizeMode="cover"
          />
        </ImageButton02>
        <Animated.View style={[ HStyle]}>
          <Actions actionHeight={actionHeight.value} />
        </Animated.View>
      </Animated.View>
      {/* <Animated.View style={OpacityStyle}>
        <CoinSelection />
      </Animated.View> */}
    </>
  );
}

export default ActionButton;

const styles = StyleSheet.create({
  v_circle: {
    top: "12%",
    left: 5,
    zIndex: 1,
    borderWidth: 5,
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    borderColor: colors.red6,
    backgroundColor: colors.red7,
  },
  btn_float: {
    width: 40,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 40 / 2,
  },
});
