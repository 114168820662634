import React from "react";
import { selectedGModeURL } from "@src/ducks/slices/gmode.slice";
import * as ScreenOrientation from "expo-screen-orientation";
import { StyleSheet, Pressable } from "react-native";
import { useAppSelector } from "@src/ducks/ducksHook";
import Icon from "@expo/vector-icons/MaterialIcons";
import useMetrics from "utils/metrics";
import _ from "lodash";

function FullScreenButton() {
  const { verticalScale } = useMetrics();
  const document: any = window.document;
  const gameURL = useAppSelector(selectedGModeURL);
  const [isFullscreen, setIsFullscreen] = React.useState(false);
  const supportedFS = typeof document.webkitCurrentFullScreenElement !== "undefined";
  const onFullScreen = () => document.body.requestFullscreen();
  const onFullscreenChange = () => setIsFullscreen(Boolean(document.fullscreenElement));

  const onLandscape = async () => {
    try {
      const result = await ScreenOrientation.getOrientationAsync();

      if ([1, 2].includes(result)) {
        await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE);
      }
    } catch (e) { /* empty */ }
  };

  React.useEffect(() => {
    if (isFullscreen) {
      onLandscape();
    }
  }, [isFullscreen]);

  React.useEffect(() => {
    document.addEventListener("fullscreenchange", onFullscreenChange);

    return () =>
      document.removeEventListener("fullscreenchange", onFullscreenChange);
  }, []);

  if(isFullscreen || !supportedFS || !_.isEmpty(gameURL)){
    return null;
  }

  return (
    <Pressable
      onPress={onFullScreen}
      style={styles.btnFullScreen} >
      <Icon name="fullscreen" size={verticalScale(70)} color="white" />
    </Pressable>
  );
}

const styles = StyleSheet.create({
  btnFullScreen: {
    top: 4,
    left: 5,
    width: 45,
    zIndex: 2,
    height: 45,
    position: "absolute",
  },
});

export default React.memo(FullScreenButton);