import React, { useState } from "react";
import { View, Image, Text } from "react-native";
import { TextInput01, TextInput02 } from "components/textinput";
import { useAuthService, useLobbyService } from "@src/ducks/hooks";
import { selectedAuthProfile, selectedAuthUserID } from "@src/ducks/slices/auth.slice";
import { useNavigation } from "@react-navigation/native";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useInputHelper } from "utils/inputs-helper";
import { MDates, Countries } from "utils/mockdata";
import { ImageButton } from "components/button";
import { useFetchPost } from "utils/api/hooks";
import { isTablet } from "utils/minheight";
import useMetrics from "utils/metrics";
import useStyles from "./styles.css";
import { images } from "@src/theme";
import moment from "moment";
import _ from "lodash";

const initialState = {
  firstName: "",
  lastName: "",
  password: "",
  cpassword: "",
  month: "",
  day: "",
  year: "",
  country: "",
  gender: ""
};

const EnterFields = () => {
  const styles = useStyles();
  const { verticalScale } = useMetrics();
  const navigation = useNavigation<any>();
  const [error, setError] = useState<any>({});
  const { onUpdateProfile } = useAuthService();
  const { onUpdateMissions } = useLobbyService();
  const userId = useAppSelector(selectedAuthUserID);
  const profile = useAppSelector(selectedAuthProfile);
  const {loading, runRequest, data} = useFetchPost();
  const {state, onDispatch, onSetInitial } = useInputHelper(initialState);
  const onChangeText = (type: string) => (value: string) => onDispatch(type)(value);

  const onSubmit = () => {
    const newErr: any = {};

    if (!state.firstName) {
      newErr.firstName = "Required";
    }
    if (!state.lastName) {
      newErr.lastName = "Required";
    }
    if (!state.password) {
      newErr.password = "Required";
    }else if (state.password.length < 6) {
      newErr.password = "Password must be at least six characters.";
    }
    if (!state.cpassword) {
      newErr.cpassword = "Required";
    }else if (state.password !== state.cpassword) {
      newErr.cpassword = "Passwords do not match.";
    }

    setError(newErr);

    if (_.isEmpty(newErr)) {
      const params = {...state};
      params.userID = userId;
      delete params.cpassword;

      if (params.month && params.day && params.year) {
        params.dateOfBirth = moment(
          `${params.month} ${params.day}, ${params.year}`,
          "MMMM DD, YYYY"
        ).format("DD-MM-YYYY");
      }
      if (params.country) {
        const index = _.findIndex(Countries, function(o) { return o.name === params.country; });
        params.countryId = index + 1;
      }

      if (params.gender) {
        const index = _.findIndex(MDates.Gender, function(o) { return o === params.gender; });
        params.genderId = index + 1;
      }

      console.log("params", params);

      runRequest("/api/v1/user/changeprofile", params);
    }
  };

  React.useEffect(() => {
    if(!_.isEmpty(data)){
      onUpdateProfile(data);
      onUpdateMissions("profifle");
      navigation.goBack();
    }
  }, [data]);

  React.useEffect(() => {
    onSetInitial({...initialState, ...profile});
  }, [profile]);

  React.useEffect(() => {
    setError({});
  }, [state]);

  return (
    <View style={[styles.bodyStyle, { height: verticalScale(isTablet ? 470 : 650)}]}>
      <Image
        source={images.bg_transparent}
        style={styles.imageBorderStyle}
        resizeMode="stretch"
      />
      <ImageButton
        source={images.btn_close}
        style={styles.btnClose}
        onPress={() => navigation.goBack()}
      />
      <View style={[styles.v_profile_header]}>
        <Image
          resizeMode="contain"
          source={images.update_profile}
          style={styles.forgotTextStyle}
        />
      </View>
      <View style={styles.v_fields}>
        <View style={styles.v_name}>
          <TextInput01
            value={state.firstName}
            placeholder="First Name *"
            error={error.firstName}
            containerStyle={styles.fn_style}
            onChangeText={onChangeText("firstName")}
          />
          <TextInput01
            value={state.lastName}
            placeholder="Last Name *"
            error={error.lastName}
            containerStyle={styles.fn_style}
            onChangeText={onChangeText("lastName")}
          />
        </View>
        <TextInput01
          hasPassword
          value={state.password}
          placeholder="Password *"
          secureTextEntry={true}
          error={error.password}
          containerStyle={styles.inputEmailStyle}
          onChangeText={onChangeText("password")}
        />
        <TextInput01
          hasPassword
          value={state.cpassword}
          placeholder="Confirm Password *"
          secureTextEntry={true}
          error={error.cpassword}
          containerStyle={styles.inputEmailStyle}
          onChangeText={onChangeText("cpassword")}
        />
        <View style={styles.v_name}>
          <Text style={styles.lbl_bday}>Birthday</Text>
          <TextInput02
            value={state.month}
            placeholder="Month"
            error={error.month}
            options={MDates.Months}
            containerStyle={[styles.dd_field, { width: "30%" }]}
            onChangeText={onChangeText("month")}
          />
          <TextInput02
            value={state.day}
            placeholder="Day"
            error={error.day}
            options={MDates.Days}
            containerStyle={styles.dd_field}
            onChangeText={onChangeText("day")}
          />
          <TextInput02
            value={state.year}
            placeholder="Year"
            error={error.year}
            options={MDates.Years}
            containerStyle={styles.dd_field}
            onChangeText={onChangeText("year")}
          />
        </View>

        <View style={styles.v_name}>
          <TextInput02
            value={state.country}
            placeholder="Country"
            error={error.country}
            options={Countries}
            containerStyle={[styles.dd_field, { width: "50%" }]}
            onChangeText={(e: any) => onDispatch("country")(e.name)}
          />
          <TextInput02
            value={state.gender}
            placeholder="Gender"
            error={error.gender}
            options={MDates.Gender}
            containerStyle={[styles.dd_field, { width: "35%" }]}
            onChangeText={onChangeText("gender")}
          />
        </View>

        <View style={styles.v_required}>
          <Text style={styles.t_required}>* Required</Text>
        </View>
        <ImageButton
          isLoad={loading}
          onPress={onSubmit}
          style={styles.btnSubmit}
          source={images.btn_update}
        />
      </View>
    </View>
  );
};

export default EnterFields;
