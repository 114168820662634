import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import { StyleSheet } from "react-native";
import { colors } from "@src/theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      zIndex: 6,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.translucent,
      ...StyleSheet.absoluteFillObject,
    },
    flex1: { flex: 1 },
    v_bodystyle: {
      zIndex: 3,
      width: 400,
      height: 300,
      alignItems: "center",
      position: "absolute",
      justifyContent: "center",
    },
    i_background: {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    btn_close: {
      zIndex: 2,
      width: "5%",
      height: "10%",
      top: "5%",
      right: "5%",
      position: "absolute",
    },

    v_center: {
      width: "100%",
      height: "100%",
      alignItems: "center",
    },
    v_body_wrapper: {
      width: "79%",
      height: "76%",
    },
    t_welcome: {
      marginTop: 20,
      textAlign: "center",
      fontFamily: "Roboto-Bold",
      color: "#FFC149",
    },
    t_description: {
      marginTop: 10,
      textAlign: "center",
      fontFamily: "Roboto-Bold",
      color: "white",
    },
    t_read: {
      marginTop: "2%",
      textAlign: "center",
      fontFamily: "Roboto-Bold",
      color: "white",
    },
    v_declare: {
      marginTop: "1%",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    v_check: {
      backgroundColor: colors.white2,
      alignItems: "center",
      justifyContent: "center",
    },
    t_declare: {
      marginLeft: 10,
      textAlign: "center",
      fontFamily: "Roboto-Bold",
      color: "white",
    },
    viewForgotStyle: {
      width: "50%",
      height: "26%",
      marginTop: "3%",
      alignItems: "center",
      justifyContent: "center",
    },
    forgotTextStyle: {},
    viewEmail: {
      width: "78%",
      height: "50%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_disclaimer_note: {
      width: "80%",
      height: "85%",
    },
    v_button: {
      width: "90%",
      height: "19%",
      alignItems: "center",
    },
    btnSubmit: { width: "18%", height: "70%", marginTop: "1%" },
    btn_collect: {
      width: "25%",
      height: "80%",
    },
  },
  {
    [DEVICE_SIZES.MD]: {
      v_bodystyle: {
        height: "80%",
      },
    },
    [DEVICE_SIZES.SM]: {
      v_bodystyle: {
        height: "75%",
      },
    },
    [DEVICE_SIZES.XS]: {
      v_bodystyle: {
        height: "83%",
      },
    },
  }
);

export default useStyles;
