import React from "react";
import { SvgProps } from "react-native-svg";

//SVGs
import BTNBig from "./btn-big.svg";
import ClickHere from "./click-here.svg";
import Close from "./close.svg";
import RoundClose from "./round-close.svg";
import BTNAddFavorite from "./add-favorite.svg";
import BTNTermsCondition from "./settings/btn-terms-condition.svg";

export interface IconProps extends SvgProps {
  name: any;
  size?: number;
  stroke?: string;
  fill?: string;
  isActive?: boolean;
}

const SVGIcon = (props: IconProps) => {
  const { name, width, height } = props;
  switch (name) {
  case "close":
    return <Close width={width || 37} height={height || 33} {...props} />;
  case "round-close":
    return <RoundClose width={width || 37} height={height || 33} {...props} />;
  case "btn-big":
    return <BTNBig width={width || 129} height={height || 46} {...props} />;
  case "click-here":
    return <ClickHere width={width || 129} height={height || 46} {...props} />;
  case "add-favorite":
    return <BTNAddFavorite width={width || 129} height={height || 46} {...props} />;
  case "btn-terms-condition":
    return <BTNTermsCondition width={width || 112} height={height || 24} {...props} />;
  default:
    return null;
  }
};

export default React.memo(SVGIcon);
