import React from "react";
import { DimensionContext } from "@src/DimensionProvider";
import { useNavigation } from "@react-navigation/native";
import { useLobbyService } from "@src/ducks/hooks";
import { ImageButton } from "components/button";
import { View, Image } from "react-native";
import { Scale } from "utils/animation";
import useStyles from "./styles.css";
import { images } from "@src/theme";

const PopupVault = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const {onChangeScreen} = useLobbyService();
  const { baseWidth } = React.useContext(DimensionContext);

  const onSeeMisison = () => {
    navigation.goBack();
    onChangeScreen({ screen: "mission" });
  };

  return (
    <View style={styles.container}>
      <Scale style={styles.container}>
        <View
          style={[
            styles.center_view,
            { width: baseWidth * 0.6103, height: baseWidth * 0.3442 },
          ]}
        >
          <Image
            style={styles.i_background}
            source={images.bg_popup}
            resizeMode="stretch"
          />
          <View style={styles.v_body}>
            <View style={styles.v_header}>
              <Image
                style={styles.i_title}
                source={images.fortune_vault}
                resizeMode="stretch"
              />
            </View>
            <View style={styles.v_middle_details}>
              <View style={styles.v_right_details}>
                <Image
                  style={styles.i_mission_key}
                  source={images.mission_vault}
                  resizeMode="stretch"
                />
              </View>
              <View style={styles.v_left_details}>
                <View style={styles.v_textwrap}>
                  <Image
                    style={styles.i_notes}
                    source={images.vault_notes}
                    resizeMode="stretch"
                  />
                </View>
                <ImageButton
                  onPress={onSeeMisison}
                  style={styles.btn_ok}
                  source={images.btn_mission}
                />
              </View>
            </View>
          </View>
        </View>
      </Scale>
    </View>
  );
};

export default PopupVault;
