import React, { useState } from "react";
import { selectedNotifications } from "@src/ducks/slices/lobby.slice";
import { useAppSelector } from "@src/ducks/ducksHook";
import { View, FlatList } from "react-native";
import useStyles from "./styles.css";
import InboxItem from "./InboxItem";

export default () => {
  const styles = useStyles();
  const [height, setHeight] = useState(0);
  const notifications = useAppSelector(selectedNotifications);

  const onLayout = (e: any) => {
    setHeight(e.nativeEvent.layout.height);
  };

  return (
    <View onLayout={onLayout} style={styles.v_body}>
      <FlatList
        data={notifications}
        showsVerticalScrollIndicator={false}
        style={[styles.flatlist, { height }]}
        ListFooterComponent={<View style={styles.height20} />}
        keyExtractor={(_: undefined, index: number) => index.toString()}
        renderItem={({ item, index }: any) => {
          return <InboxItem key={index.toString()} {...{ item, height }} />;
        }}
      />
    </View>
  );
};
