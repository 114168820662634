import React from "react";
import { DimensionContext } from "@src/DimensionProvider";
import { useNavigation } from "@react-navigation/native";
import Loading from "components/loading/Loading";
import { ImageButton } from "components/button";
import { View, Image } from "react-native";
import { InboxContext } from "../provider";
import useStyles from "./styles.css";
import InboxList from "./InboxList";
import { images } from "@src/theme";

const InboxScreen = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { isLoading } = React.useContext(InboxContext);
  const { baseWidth } = React.useContext(DimensionContext);

  return (
    <View style={styles.container}>
      <View
        style={[
          styles.center_view,
          { width: baseWidth * 0.5611, height: baseWidth * 0.3076 },
        ]}
      >
        <Image
          style={styles.i_background}
          source={images.bg_inbox}
          resizeMode="stretch"
        />
        <View style={styles.v_inbox}>
          <Image
            style={styles.i_inbox}
            source={images.logo_inbox}
            resizeMode="stretch"
          />
          <Image
            style={styles.i_message}
            source={images.ic_message}
            resizeMode="stretch"
          />
        </View>
        <ImageButton
          onPress={() => navigation.pop()}
          style={styles.btn_close}
          source={images.btn_close}
        />
        <InboxList />
        <Loading isLoading={isLoading} />
      </View>
    </View>
  );
};

export default InboxScreen;
