import { images } from 'theme';

const Inbox = [
  {
    name: 'love slot',
    text: images.loveslot,
    avatar: images.avatar_popstar_wb,
    games: images.candy,
    details: {
      gameName: 'Candy Dreams',
      gameTypeID: 1,
      packageFilePath: 'fullstate\\html5\\evoplay\\candydreams',
      code: 296,
    },
  },
  {
    name: 'try your luck',
    text: images.tryluck,
    avatar: images.avatar_casino_wb,
    games: images.pachin,
    details: {
      gameName: 'Pachin Girl',
      gameTypeID: 3,
      packageFilePath: 'instant\\html5\\evoplay\\pachingirl',
      code: 5743,
      id: 103,
    },
  },
];

export default Inbox;
