import React from "react";
import { Pressable, StyleSheet, Animated } from "react-native";
import { AuthContext } from "@src/AuthProvider";
import ShinyEffect from "./ShinyEffect";

const AnimatedButton = Animated.createAnimatedComponent(Pressable);

const ImageButton03 = (props: any) => {
  const { handleClick } = React.useContext(AuthContext);
  const { children, style, onPress, disabled, scaleSize } = props;
  const animated = React.useRef(new Animated.Value(1)).current;

  const onPressIn = () => {
    Animated.timing(animated, {
      toValue: scaleSize || 0.9,
      duration: 100,
      useNativeDriver: false,
    }).start();
  };

  const onPressOut = () => {
    Animated.timing(animated, {
      toValue: 1,
      duration: 100,
      useNativeDriver: false,
    }).start();
  };

  const onClick = () => {
    onPress();
    handleClick();
  };

  return (
    <AnimatedButton
      onPress={onClick}
      disabled={disabled}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      style={[styles.buttonStyle, style, { transform: [{ scale: animated }] }]}
    >
      <ShinyEffect />
      {children}
    </AnimatedButton>
  );
};

const styles = StyleSheet.create({
  buttonStyle: {
    width: "40%",
    height: 70,
  },
});

export default ImageButton03;
