import SVGIcon from "@assets/svg";
import { AuthContext } from "@src/AuthProvider";
import React from "react";
import { ActivityIndicator, Pressable, StyleSheet, Text, View } from "react-native";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import colors from "theme/colors";

const AnimatedButton = Animated.createAnimatedComponent(Pressable);

const ImageButton06 = (props: any) => {
  const animated = useSharedValue(1);
  const { handleClick } = React.useContext(AuthContext);
  const { style, onPress, disabled, scaleSize } = props;

  const onPressIn = () => {
    animated.value = withTiming(scaleSize || 0.9, { duration: 100 });
  };

  const onPressOut = () => {
    animated.value = withTiming(1, { duration: 100 });
  };

  const animateStyle = useAnimatedStyle(() => {
    return {
      transform: [{ scale: animated.value } ],
    };
  }, []);

  const onClick = () => {
    onPress();
    handleClick();
  };

  return (
    <AnimatedButton
      onPress={onClick}
      disabled={disabled}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      style={[styles.buttonStyle, style, animateStyle]}
    >
      <View style={styles.imageButtonStyle}>
        <SVGIcon name="btn-big" width={props.width} height={props.height} />
      </View>
      {props.isLoading ? (
        <ActivityIndicator
          style={styles.spinner}
          color={colors.white}
          size="small"
          animating
        />
      ) : <Text selectable={false} style={[styles.t_label, props.labelStyle]}>{props.label}</Text>}
      
    </AnimatedButton>
  );
};

const styles = StyleSheet.create({
  buttonStyle: {
    alignItems: "center",
    justifyContent: "center",
  },
  imageButtonStyle: {
    position: "absolute"
  },
  spinner: { position: "absolute", zIndex: 1 },
  t_label: {
    zIndex: 2,
    fontFamily: "Roboto_Bold", 
    color: colors.white, 
    position: "absolute",
    marginBottom: "6%",
    marginRight: "2%",
  },
});

export default ImageButton06;
