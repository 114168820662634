import React, { useEffect } from "react";
import { View, TouchableOpacity, useWindowDimensions } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { TranslateY, Opacity } from "utils/animation";
import PaymentOption from "./payment-option";
import PaymentProvider from "./provider";
import useStyles from "./styles.css";
import { colors } from "@src/theme";
import moment from "moment";
import _ from "lodash";

const PaymentGateway = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const sendDataToGTM = useGTMDispatch();
  const navigation = useNavigation<any>();
  const { width } = useWindowDimensions();
  const amount = route?.params?.amount;
  const currency = route?.params?.currency;
  const productId = route?.params?.productId;

  useEffect(() => {
    sendDataToGTM({
      event: "select_item",
      ecommerce: {
        items: [
          {
            item_id: _.uniqueId(`id-${moment()}`),
            item_name: productId,
            currency: currency,
            price: amount,
            quantity: 1,
          },
        ],
      },
    });
  }, []);

  return (
    <PaymentProvider>
      <View style={styles.container}>
        <TouchableOpacity
          activeOpacity={1}
          onPress={() => navigation.goBack()}
          style={[styles.v_opacity, { backgroundColor: colors.translucent }]} >
          <Opacity style={styles.v_opacity} toValue={1} duration={400} />
        </TouchableOpacity>
        <TranslateY style={[styles.v_dropin, { width: width * 0.5 }]}>
          <PaymentOption />
        </TranslateY>
      </View>
    </PaymentProvider>
  );
};

export default PaymentGateway;
