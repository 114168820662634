import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import { StyleSheet } from "react-native";
import { colors } from "@src/theme";
import { verticalScale } from "react-native-size-matters";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    bodyStyle: {
      width: "58%",
      height: verticalScale(250),
      alignItems: "center",
      justifyContent: "center",
    },
    imageBorderStyle: {
      width: "100%",
      height: "100%",
      ...StyleSheet.absoluteFillObject,
    },
    bottomHeight: {
      width: 100,
      height: "18%",
    },
    header: {
      zIndex: 1,
      fontSize: 30,
      paddingTop: 48,
      color: "#6C0B7B",
      marginBottom: 40,
      fontFamily: "Roboto_Medium",
    },

    // ENTER EMAIL COMPONENT STYLE
    viewForgotStyle: {
      width: "60%",
      height: "20%",
      alignItems: "center",
      justifyContent: "center",
    },
    forgotTextStyle: { width: "100%", height: "100%" },
    viewEmail: {
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    txtEmailNote: {
      fontSize: 16,
      textAlign: "center",
      color: colors.white,
      fontFamily: "Roboto_Regular",
    },
    inputEmailStyle: { marginTop: 10, height: 50 },
    height20: { height: 20 },
    btnSubmit: { height: 60, width: 140, marginLeft: 10 },
    imageCloseStyle: { height: 30, width: 30 },
    textAlign: { textAlign: "center", paddingLeft: 0, fontSize: 16 },

    // RESET PASSWORD
    ti_reset: { marginTop: 20, height: "14%" },
    btn_reset_password: { height: "20%", width: "30%", marginLeft: 10 },

    v_check_email: {
      width: "55%",
      height: verticalScale(250),
      alignItems: "center",
      justifyContent: "center",
    },

    btnClose: {
      width: 40,
      height: 40,
      zIndex: 1,
      top: "5%",
      right: "4%",
      position: "absolute",
    },
  },
  {
    [DEVICE_SIZES.MD]: {
      inputEmailStyle: {
        height: 40,
      },
      txtEmailNote: { fontSize: 15 },
      btnSubmit: {
        width: 100,
        height: 50,
      },
      btnClose: {
        width: 35,
        height: 35,
      },
    },
    [DEVICE_SIZES.SM]: {
      inputEmailStyle: {
        height: 30,
      },
      txtEmailNote: { fontSize: 14 },
      btnSubmit: {
        width: 100,
        height: 45,
      },
      btnClose: {
        width: 30,
        height: 30,
      },
    },
    [DEVICE_SIZES.XS]: {
      inputEmailStyle: {
        height: 30,
      },
      txtEmailNote: { fontSize: 14 },
      btnSubmit: {
        width: 100,
        height: 45,
      },
      btnClose: {
        width: 30,
        height: 30,
      },
    },
  }
);

export default useStyles;
