import React from "react";
import { View, StyleSheet } from "react-native";
import { CoinsInsert } from "utils/animation";
import { MCoins } from "utils/mockdata";

const CoinsView = ({ coordination, silver }: any) => {
  const [visible, setVisible] = React.useState(false);

  const onReset = () => {
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      setVisible(false);
    }, 3000);
  };

  React.useEffect(() => {
    if (silver > 0) {
      setVisible(true);
      onReset();
    }
  }, [silver]);

  if (!visible) {
    return null;
  }

  return (
    <View pointerEvents="box-none" style={styles.container}>
      {visible &&
        MCoins.map((item, index) => {
          return (
            <CoinsInsert
              key={index.toString()}
              duration={item.duration}
              transX={coordination.transX}
              transY={coordination.transY}
            />
          );
        })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    flex: 1,
    backgroundColor: "transparent",
    zIndex: 200,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default CoinsView;
