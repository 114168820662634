import React, { useState } from "react";
import { useRoute } from "@react-navigation/native";
import { TextInput01 } from "components/textinput";
import { View, Image, Text } from "react-native";
import { ImageButton } from "components/button";
import { useForgotService } from "@src/ducks/hooks";
import useStyles from "./styles.css";
import { images } from "@src/theme";
import _ from "lodash";

const ResetPassword = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const otp = route?.params?.otp;
  const email = route?.params?.email;
  const [error, setError] = useState<any>({});
  const [newPassword, setNewPassword] = useState("");
  const {isLoading, onResetPassword} = useForgotService();
  const [confirmPassword, setConfirmPassword] = useState("");
  
  const onSubmit = () => {
    const newErr: any = {};
    if (!newPassword) {
      newErr.newPassword = "Required.";
    } else if (!confirmPassword) {
      newErr.newPassword = "Required.";
    } else if (newPassword !== confirmPassword) {
      newErr.newPassword = "Not match.";
    }

    setError(newErr);

    if (_.isEmpty(newErr)) {
      const params = {
        otp,
        email,
        password: newPassword,
      };

      onResetPassword(params);
    }
  };

  return (
    <View style={[styles.bodyStyle]}>
      <Image
        source={images.bg_transparent}
        style={styles.imageBorderStyle}
        resizeMode="stretch"
      />
      <View style={styles.v_center_reset}>
        <View style={[styles.viewEmail]}>
          <View style={[styles.viewForgotStyle]}>
            <Image
              resizeMode="contain"
              source={images.reset_password}
              style={styles.forgotTextStyle}
            />
          </View>
          <Text style={styles.txtEmailNote}>
            Strong passwords include numbers, letters and punctuation marks
          </Text>
          <TextInput01
            value={newPassword}
            placeholder="New Password"
            secureTextEntry={false}
            error={error.newPassword}
            onChangeText={setNewPassword}
            disableFullscreenUI={true}
            inputStyle={styles.textAlign}
            containerStyle={[styles.ti_reset, { marginTop: 20 }]}
          />
          <TextInput01
            value={confirmPassword}
            placeholder="Confirm New Password"
            secureTextEntry={false}
            onChangeText={setConfirmPassword}
            disableFullscreenUI={true}
            error={error.confirmPassword}
            inputStyle={styles.textAlign}
            containerStyle={styles.ti_reset}
          />
        </View>
        <ImageButton
          onPress={onSubmit}
          isLoad={isLoading}
          style={styles.btn_reset_password}
          source={images.btnResetPassword}
        />
      </View>
    </View>
  );
};

export default ResetPassword;
