import React, { useRef, useEffect } from 'react';
import { Animated, Easing, Text } from 'react-native';
const AnimatedText = Animated.createAnimatedComponent(Text);

const ScaleLoopAnimated = ({ children, style, outputRange }: any) => {
  const animated = useRef(new Animated.Value(0)).current;

  const handleAnimation = () => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(animated, {
          toValue: 1,
          duration: 1500,
          easing: Easing.linear,
          useNativeDriver: false,
        }),
        Animated.timing(animated, {
          toValue: 0,
          duration: 1500,
          easing: Easing.linear,
          useNativeDriver: false,
        }),
      ])
    ).start();
  };

  const scale = animated.interpolate({
    inputRange: [0, 1],
    outputRange: outputRange || [0.8, 1],
    extrapolate: 'clamp',
  });

  const opacity = animated.interpolate({
    inputRange: [0, 1],
    outputRange: [0.5, 1],
    extrapolate: 'clamp',
  });

  useEffect(() => {
    handleAnimation();
  }, []);

  return (
    <AnimatedText
      style={[
        style,
        {
          opacity,
          transform: [
            {
              scale,
            },
          ],
        },
      ]}
    >
      {children}
    </AnimatedText>
  );
};

export default ScaleLoopAnimated;
