import { ForSlideProps } from './types';

const FadeInterpolator = ({ current }: ForSlideProps) => {
  return {
    cardStyle: {
      opacity: current.progres,
    },
  };
};

export default FadeInterpolator;
