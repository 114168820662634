import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../ducksHook";
import { selectedAuthSession, selectedAuthToken } from "../slices/auth.slice";
import { settingsActions } from "../slices/settings.slice";

type ServiceOperators = {
  onChangeSettings: (params: boolean) => void;
  onChangeProfile: (params: boolean) => void;
  onDeactivatingRequest: () => void;
};

export const useSettingsService = (): Readonly<ServiceOperators> => {
  const dispatch = useAppDispatch();
  const token  = useAppSelector(selectedAuthToken);
  const session = useAppSelector(selectedAuthSession);
  const userId = session?.userId;

  return {
    onChangeSettings: useCallback(
      (params: boolean) =>  dispatch(settingsActions.hasSettingsOpen(params)),
      [dispatch]
    ),
    onChangeProfile: useCallback(
      (params: boolean) =>  dispatch(settingsActions.hasProfileOpen(params)),
      [dispatch]
    ),
    onDeactivatingRequest: useCallback(
      () =>  dispatch(settingsActions.deactivateAccount({userId, token})),
      [dispatch, userId, token]
    ),
  };
};

export default useSettingsService;
