import React, { useRef, useEffect } from 'react';
import { Animated } from 'react-native';

const HeightAnimated = ({
  children,
  style,
  toValue,
  duration,
  outputRange,
}: any) => {
  const animated = useRef(new Animated.Value(0)).current;

  const animatedHeight = animated.interpolate({
    inputRange: [0, 1],
    outputRange: outputRange || ['0%', '100%'],
    extrapolate: 'clamp',
  });

  useEffect(() => {
    Animated.timing(animated, {
      toValue: toValue,
      duration: duration || 250,
      useNativeDriver: false,
    }).start();
  }, [toValue]);

  return (
    <Animated.View
      style={[
        style,
        {
          height: animatedHeight,
        },
      ]}
    >
      {children}
    </Animated.View>
  );
};

export default HeightAnimated;
