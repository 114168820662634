import { Asset } from "expo-asset";
import { useCallback, useEffect, useState } from "react";

function useCacheImage(arrayAssets: any[]) {
  const [appIsReady, setAppIsReady] = useState(false);

  function cacheImages(assets: any[]) {
    return assets.map(image => {
      return Asset.fromModule(image).downloadAsync();
    });
  }

  const loadResourcesAndDataAsync = useCallback(async () => {
    try {
      const imageAssets = cacheImages(arrayAssets);
      await Promise.all(imageAssets);
    } catch (e) {
      console.warn(e);
    } finally {
      // Tell the application to render
      setAppIsReady(true);
    }
  },[arrayAssets]);
 
  // Load any resources or data that you need before rendering the app
  useEffect(() => {
    loadResourcesAndDataAsync();
  }, []);

  return appIsReady;
}

export default useCacheImage;
