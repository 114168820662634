import React, { useEffect } from "react";
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
  withRepeat,
} from "react-native-reanimated";

const Scale = (props: any) => {
  const animated = useSharedValue(1);

  const animateStyle = useAnimatedStyle(() => {
    return {
      transform: [{ scale: animated.value }],
    };
  }, []);

  useEffect(() => {
    animated.value = withRepeat(withTiming(1.1, { duration: 500 }), -1, true);
  }, []);

  return (
    <Animated.View style={[props.style, animateStyle]}>
      {props.children}
    </Animated.View>
  );
};

export default Scale;
