import React from "react";
import {
  View,
  Image,
  Text,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
// import useActions from "modules/login/useActions";
import useStyles from "./styles.css";
import { images } from "@src/theme";
import { useForgotService } from "@src/ducks/hooks";

const CheckEmail = ({ email }: any) => {
  const styles = useStyles();
  const {onForgot, isLoading}= useForgotService();

  const onSubmit = () => {
    onForgot(email);
  };

  return (
    <View style={[styles.v_check_email]}>
      <Image
        source={images.bg_transparent}
        style={styles.imageBorderStyle}
        resizeMode="stretch"
      />
      <View style={[styles.viewEmail]}>
        <View style={[styles.viewForgotStyle]}>
          <Image
            resizeMode="contain"
            source={images.check_email}
            style={styles.forgotTextStyle}
          />
        </View>
        <Text style={styles.txtEmailNote}>
          We've sent password reset instructions to:
        </Text>
        <Text style={[styles.txtEmailNote, { fontFamily: "Roboto_Bold" }]}>
          {email}
        </Text>
        <View style={styles.height20} />
        <Text style={styles.txtEmailNote}>
          If it doesn't arrive soon, check your spam folder or
        </Text>

        {isLoading ? (
          <ActivityIndicator
            style={{ marginTop: 10 }}
            size={20}
            color="black"
            animating
          />
        ) : (
          <TouchableOpacity style={{ padding: 10 }} onPress={onSubmit}>
            <Text
              style={[styles.txtEmailNote, { textDecorationLine: "underline" }]}
            >
              send the email again.
            </Text>
          </TouchableOpacity>
        )}
      </View>
      <View style={styles.bottomHeight} />
    </View>
  );
};

export default CheckEmail;
