import { SagaIterator } from "@redux-saga/core";
import { call, delay, put, select, takeEvery } from "redux-saga/effects";

// Types
import * as Types from "../types";

// API
import {
  check3hoursReward,
  checkDailyReward,
  checkWeeklyMissions,
  checkingAuthentication,
  collectMissionReward,
  collectSigningBonus,
  createMyFavoriteGames,
  deleteMyFavoriteGames,
  fetchAvatarTier,
  fetchGames,
  fetchMissions,
  fetchNotifications,
  fetchProducts,
  fetchRandomRewards,
  fetchUserRank,
  jadeEggAccumulation,
  purchaseItem,
  recordPurchase,
  recordPurchaseJade,
  recordPurchaseSubscription,
  walletBalance
} from "@src/utils/api";

// Slice
import { compileTransformation, featuresFilter, getGamesByCategoryId, switchSelectedFavoriteGames } from "utils/transform-helper";
import { authActions, selectedAuthToken, selectedAuthUserID } from "../slices/auth.slice";
import { lobbyActions, selectedCategoryId, selectedGamesOrigin } from "../slices/lobby.slice";

function* handleLobbyRequest(action: {
  type: typeof lobbyActions.lobbyRequest;
  payload: Types.SessionValue;
}): SagaIterator {
  try {
    const token = yield call(checkingAuthentication, action.payload);

    if(action.payload.token !== token){
      yield put(authActions.refreshToken(token));
    }

    const balance = yield call(walletBalance, {...action.payload, token});
    yield put(authActions.updateWallet(balance.data));

    const jadeEgg = yield call(jadeEggAccumulation, {...action.payload, token});
    yield put(lobbyActions.jadeAmount(jadeEgg.data.amount));

    const userRank = yield call(fetchUserRank, {...action.payload, token});
    yield put(lobbyActions.userRank(userRank));

    const randomRewards = yield call(fetchRandomRewards, {...action.payload, token});
    yield put(lobbyActions.randomRewards(randomRewards.data));

    const missions = yield call(fetchMissions, {...action.payload, token});
    yield put(lobbyActions.missions(missions.data));

    const avatarTier = yield call(fetchAvatarTier, {...action.payload, token});
    yield put(lobbyActions.avatarTier(avatarTier));

    const gamesOrigin = yield call(fetchGames, {...action.payload, token});
    const gamesTransform = yield call(compileTransformation, gamesOrigin);
    const featuredList = yield call(featuresFilter, gamesOrigin);
    yield put(lobbyActions.games({gamesOrigin, transform: gamesTransform, featuredList}));

    const serverTime = yield call(checkDailyReward, {...action.payload, token});
    yield put(lobbyActions.serverTime(serverTime));

    const weeklyMissionActive = yield call(checkWeeklyMissions, {...action.payload, token});
    yield put(lobbyActions.isWeeklyMissionActive(weeklyMissionActive.data));

    const hourlyReward = yield call(check3hoursReward, {...action.payload, token});
    yield put(lobbyActions.hourlyReward(hourlyReward));

    const notifications = yield call(fetchNotifications, {...action.payload, token});
    yield put(lobbyActions.notifications(notifications.data));

    const products = yield call(fetchProducts, {});
    yield put(lobbyActions.products(products));

    yield put(lobbyActions.lobbySuccess());
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Session Expired"));
    }
    yield put(lobbyActions.lobbyFailure(error?.error ?? error));
  }
}

function* handleCollectSigningBonus(action: {
  type: typeof lobbyActions.signingBonusRequest;
  payload: Types.SessionValue;
}): SagaIterator {
  try {
    yield call(collectSigningBonus, action.payload);
    const balance = yield call(walletBalance, action.payload);
    yield put(authActions.updateWallet(balance.data));

    yield put(lobbyActions.signingBonusSuccess());
    yield put(authActions.updateUserRewards(action.payload.userRewardsId));
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Session Expired"));
    }
    yield put(lobbyActions.signingBonusFailed());
  }
}

function* handleMissionReward(action: {
  type: typeof lobbyActions.missionRewardRequest;
  payload: Types.SessionValue;
}): SagaIterator {
  try {
    yield call(collectMissionReward, action.payload);

    yield put(lobbyActions.isWeeklyMissionActive(true));

    const balance = yield call(walletBalance, action.payload);
    yield put(authActions.updateWallet(balance.data));

    yield put(lobbyActions.signingBonusSuccess());
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Session Expired"));
    }
    yield put(lobbyActions.signingBonusFailed());
  }
}

function* handlePurchaseRequest(action: {
  type: typeof lobbyActions.purchaseRequest;
  payload: Types.Transaction;
}): SagaIterator {
  try {
    const result = yield call(purchaseItem, action.payload);

    if (action.payload.isShop || action.payload.isJadeEgg) {
      const params = {
        token: action.payload.token || "",
        userId: action.payload.userId,
        paymentRefNo: action.payload.transactionNo,
        purchaseItem: action.payload.purchaseItem,
      };
      yield call(recordPurchase, params);
      yield put(lobbyActions.updateMissions("purchase 1x on shop"));
    }

    if (action.payload.isSubscription) {
      const params = {
        userId: action.payload.userId,
        paymentRefNo: action.payload.transactionNo,
        subscriptionId: action.payload.purchaseItem,
      };
      yield call(recordPurchaseSubscription, params);
      yield put(lobbyActions.updateMissions("purchase 1x on shop"));
    }

    yield put(lobbyActions.purchaseSuccess({ ...action.payload, ...result }));
    yield delay(1000);
    yield put(lobbyActions.purchaseSuccess({}));
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Session Expired"));
    }
    yield put(lobbyActions.purchaseFailure());
  }
}

function* handlePurchaseSuccess(action: {
  type: typeof lobbyActions.purchaseSuccess;
  payload: Types.Transaction;
}): SagaIterator {
  try {
    if(action.payload.isJadeEgg){
      yield call(recordPurchaseJade, action.payload);
      const jadeEgg = yield call(jadeEggAccumulation, action.payload);
      yield put(lobbyActions.jadeAmount(jadeEgg.data.amount));
    }
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Session Expired"));
    }
  }
}

function* handleBalanceRequest(action: {
  type: typeof lobbyActions.balanceRequest;
  payload: Types.SessionValue;
}): SagaIterator {
  try {
    const balance = yield call(walletBalance, action.payload);
    yield put(authActions.updateWallet(balance.data));
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Session Expired"));
    }
  }
}

function* handleSwitchCategory(action: {
  type: typeof lobbyActions.changeCategory;
  payload: number;
}): SagaIterator {
  try {
    const gamesOriginal = yield select(selectedGamesOrigin);
    const filterGamesByCategoryId = yield call(getGamesByCategoryId, gamesOriginal, action.payload);
    const gamesTransform = yield call(compileTransformation, filterGamesByCategoryId);
    yield put(lobbyActions.games({ transform: gamesTransform}));
  } catch (error: any) {
    // empty
  }
}

function* handleChangeFavoriteGames(action: {
  type: typeof lobbyActions.changeFavoriteGame;
  payload: any;
}): SagaIterator {
  try {
    const token = yield select(selectedAuthToken);
    const userId = yield select(selectedAuthUserID);
    const categoryId = yield select(selectedCategoryId);
    let gamesOrigin = yield select(selectedGamesOrigin);
    gamesOrigin = yield call(switchSelectedFavoriteGames, gamesOrigin, action.payload.id);
    yield put(lobbyActions.games({ gamesOrigin }));
    gamesOrigin = yield call(getGamesByCategoryId, gamesOrigin, categoryId);
    const gamesTransform = yield call(compileTransformation, gamesOrigin);
    yield put(lobbyActions.games({ transform: gamesTransform }));

    if(action.payload.isFavourite === 1){
      yield call(deleteMyFavoriteGames, {
        "gameId": action.payload.id,
        "userId": userId
      },token);
    }else {
      yield call(createMyFavoriteGames, {
        "gameId": action.payload.id,
        "userId": userId
      }, token);
    }
  } catch (error: any) {
    // empty
  }
}

// Watcher Saga
function* lobbyWatcherSaga(): SagaIterator {
  yield takeEvery(lobbyActions.lobbyRequest.type, handleLobbyRequest);
  yield takeEvery(lobbyActions.signingBonusRequest.type, handleCollectSigningBonus);
  yield takeEvery(lobbyActions.missionRewardRequest.type, handleMissionReward);
  yield takeEvery(lobbyActions.purchaseRequest.type, handlePurchaseRequest);
  yield takeEvery(lobbyActions.purchaseSuccess.type, handlePurchaseSuccess);
  yield takeEvery(lobbyActions.balanceRequest.type, handleBalanceRequest);
  yield takeEvery(lobbyActions.changeCategory.type, handleSwitchCategory);
  yield takeEvery(lobbyActions.changeFavoriteGame.type, handleChangeFavoriteGames);
}

export default lobbyWatcherSaga;
