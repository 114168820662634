import _ from "lodash";
import moment from "moment";

const descendingComparator = (a: any, b: any, orderBy: string) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order: any, orderBy: string) => {
  return order === "descending"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
};

export const applySortFilter = (array: any, comparator: any) => {
  const stabilizedThis = array.map((el: any, index: number) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el: any) => el[0]);
};

export const getSilverGold = (array: any = []) => {
  const coinTypes = ["SILVER", "GOLD", "GOLD BONUS"];
  const balanceByCoinType: any = {};

  // Initialize balance for each coin type to 0
  coinTypes.forEach((coinType) => {
    balanceByCoinType[coinType] = 0;
  });

  // Calculate the balance for each coin type
  array.forEach((item: any) => {
    const coinTypeName = item.coinType.name;
    if (coinTypes.includes(coinTypeName)) {
      balanceByCoinType[coinTypeName] += item.amount;
    }
  });

  return balanceByCoinType;
};

export const checkMissionsKeys = (newState: any, type: any) => {
  newState = newState.reduce((result: any, value: any) => {
    const name = value?.mission?.name ?? "";

    if (type === "clearkey" && value.hasKey) {
      result.push({
        ...value,
        hasKey: null,
      });

      return result;
    }

    if (value.status === "ACTIVE" && name.toLowerCase().includes(type)) {
      result.push({
        ...value,
        status: "COMPLETED",
        completedDate: moment().format(),
        hasKey: true,
      });
    } else {
      result.push(value);
    }

    return result;
  }, []);

  return newState;
};

export const readNotification = (newState: any[], notifcationDetals: any) => {
  const itemIndex = _.find(newState, { id: notifcationDetals.id });

  if (itemIndex.status === "READ") {
    return newState;
  }

  return _.map(newState, (item: any) => {
    if (item.id === notifcationDetals.id && item.status !== "READ") {
      return { ...item, ...notifcationDetals };
    } else {
      return item;
    }
  });
};

export const deleteNotification = (newState: any[], notifcationDetals: any) => {
  const index = _.findIndex(newState, { id: notifcationDetals.id });

  if(index !== -1){
    newState.splice(index, 1);
  }

  return newState;
};

export const userRewardsUpdate = (userRewards: any[], userRewardsId: any) => {
  return userRewards.map((item: any) => {
    if (item.id === userRewardsId) {
      return { ...item, status: "CLAIMED" };
    } else {
      return item;
    }
  });
};

export const schedulePopup = () => {
  const day = moment().day();

  switch(day){
  case 1:
    return "WeeklyBooster";
  case 2:
    return "HotDeals";
  case 3:
    return "HighRoller";
  case 5:
    return "TGIF";
  default: 
    return "StarterPack";
  }
};

// CHECKING IF ID IS INCLUDED IN FAVORITES ARRAY
export const checkIFFavorites = (favorites: any[], id: number) => {
  return favorites.some(element => {
    if (element.game.id === id) {
      return true;
    }
  
    return false;
  });
};
