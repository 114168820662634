import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import { StyleSheet } from "react-native";
import { colors } from "@src/theme";

const useStyles: any = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      zIndex: 6,
      alignItems: "center",
      justifyContent: "center",
      ...StyleSheet.absoluteFillObject,
      backgroundColor: colors.translucent,
    },
    center_view: {
      width: "60%",
      height: "76%",
      alignItems: "center",
      justifyContent: "center",
    },
    v_body: {
      zIndex: 2,
      top: "-1%",
      width: "94%",
      height: "91%",
      left: "-0.5%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_background: {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    v_header: {
      height: "17%",
      width: "80%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_title: { width: "70%", height: "60%" },
    v_middle_details: { height: "83%", width: "100%", flexDirection: "row" },
    v_right_details: {
      width: "55%",
      height: "106%",
      marginTop: "3%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_mission_key: { width: "100%", height: "106%", top: "-9%" },
    v_left_details: {
      zIndex: 2,
      height: "100%",
      width: "45%",
    },
    v_textwrap: {
      width: "115%",
      height: "60%",
      top: "4%",
      left: "-20%",
      position: "absolute",
    },
    i_notes: { width: "100%", height: "100%" },
    btn_ok: {
      zIndex: 2,
      left: "10%",
      width: "55%",
      height: "18%",
      bottom: "9%",
      position: "absolute",
    },
  },
  {
    [DEVICE_SIZES.MD]: {
      center_view: {
        width: "62%",
      },
    },
    [DEVICE_SIZES.SM]: {
      center_view: {
        width: "70%",
      },
    },
    [DEVICE_SIZES.XS]: {
      center_view: {
        width: "70%",
      },
    },
  }
);

export default useStyles;
