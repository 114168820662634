import React from "react";
import { AuthContext } from "@src/AuthProvider";
import { Pressable } from "react-native";
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
} from "react-native-reanimated";

const AnimatedButton = Animated.createAnimatedComponent(Pressable);

const ImageButton05 = (props: any) => {
  const animated = useSharedValue(1);
  const { handleClick } = React.useContext(AuthContext);
  const { children, style, onPress, disabled, scaleSize } = props;

  const onPressIn = () => {
    animated.value = withTiming(scaleSize || 0.9, { duration: 100 });
  };

  const onPressOut = () => {
    animated.value = withTiming(1, { duration: 100 });
  };

  const animateStyle = useAnimatedStyle(() => {
    return {
      transform: [{ scale: animated.value } ],
    };
  }, []);

  const onClick = () => {
    onPress();
    handleClick();
  };

  return (
    <AnimatedButton
      onPress={onClick}
      disabled={disabled}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      style={[style, animateStyle]}
    >
      {children}
    </AnimatedButton>
  );
};

export default ImageButton05;
