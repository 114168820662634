import React from "react";
import { Pressable, View, Text } from "react-native";
import Icon from "@expo/vector-icons/MaterialIcons";
import NewWindow from "react-new-window";
import useStyles from "./styles.css";

function NewWindowPayment(props: any) {
  const styles = useStyles();
  const windowRef = React.useRef<any>({});

  const onWindowFocus = () => {
    windowRef.current.window.focus();
  };

  const onUnload = () => props.onClose();

  React.useEffect(() => {
    const checkUrlInterval = setInterval(() => {
      if (windowRef.current) {
        const paymentWindowUrl = windowRef.current.window.location.href;

        console.log("paymentWindowUrlpaymentWindowUrl", paymentWindowUrl);

        if (paymentWindowUrl.includes("/bitpace/success") || 
          paymentWindowUrl.includes("/apcopay/success") || 
          paymentWindowUrl.includes("<Result>OK</Result>") ||
          paymentWindowUrl.includes("%3cResult%3eOK%3c%2fResult%3e")) {
          clearInterval(checkUrlInterval);
          windowRef.current.window.close();
          props.onPaymentSuccess();
        }else if (paymentWindowUrl.includes("/bitpace/error") || 
          paymentWindowUrl.includes("/apcopay/error") ||
          paymentWindowUrl.includes("<Result>CANCEL</Result>") ||
          paymentWindowUrl.includes("%3cResult%3eCANCEL%3c%2fResult%3e")) {
          clearInterval(checkUrlInterval);
          windowRef.current.window.close();
          props.onClose();
        }else if (paymentWindowUrl.startsWith("https://deposit-sandbox.bitpace.com/sci/fixed-deposit") && paymentWindowUrl.includes("/fail")) {
          clearInterval(checkUrlInterval);
          windowRef.current.window.close();
          props.onClose();
        }
      }
    }, 1000);

    return () => clearInterval(checkUrlInterval);
  }, [windowRef.current]);

  return (
    <View style={styles.window_container}>
      <Pressable style={styles.btn_close} onPress={props.onClose}>
        <Icon name="close" size={25} color="gray" />
      </Pressable>
      <Pressable onPress={onWindowFocus}>
        <Text style={styles.window_t_payment}>Payment</Text>
        <Text style={styles.window_t_notes}>Don't see the secure browser? {"\n"}We'll help you re-launch the window to complete your purchase</Text>
        <Text style={styles.window_t_click}>Click to Continue</Text>
      </Pressable>
      <NewWindow url={props.url} ref={windowRef} center="screen" 
        onUnload={onUnload}
        onBlock={() => alert("Popup is blocked!")}
        features={{popup: true, width: 600, height: 700}} 
      />
    </View>
  );
}

export default NewWindowPayment;