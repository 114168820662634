import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "@src/theme";

const useStyles = CreateResponsiveStyle(
  {
    btn_buynow: {
      width: "100%",
      height: 60,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.green2,
    },
    i_bitpace: { width: "30%", height: "70%" },
  },
  {
    [maxSize(DEVICE_SIZES.MD)]: {
      btn_buynow: {
        height: 48
      }
    },
  }
);

export default useStyles;