import { CreateResponsiveStyle } from "rn-responsive-styles";
import { StyleSheet } from "react-native";
import { colors } from "@src/theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      zIndex: 6,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.translucent,
      ...StyleSheet.absoluteFillObject
    },
    center_view: {
      width: "70%",
      height: "85%",
      alignItems: "center",
      justifyContent: "center",
    },
    v_body: {
      width: "103%",
      height: "81%",
      flexDirection: "row",
    },
    i_background: {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    btn_close: {
      width: "6%",
      height: "12%",
      top: -10,
      right: -10,
      zIndex: 2,
      position: "absolute",
    },
  }
);

export default useStyles;
