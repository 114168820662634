import React from "react";
import { selectedSigningSuccess } from "@src/ducks/slices/signup.slice";
import { useAppSelector } from "@src/ducks/ducksHook";
import SignupCompleted from "./signup-completed";
import { ImageBackground } from "react-native";
import EnterFields from "./enter-fields";
import useStyles from "./styles.css";
import { images } from "@src/theme";

const Signup = () => {
  const styles = useStyles();
  const isSignIn = useAppSelector(selectedSigningSuccess);

  return (
    <ImageBackground
      source={images.gw_bg}
      style={[styles.container]}
      resizeMode="stretch"
    >
      {isSignIn ? <SignupCompleted /> : <EnterFields />}
    </ImageBackground>
  );
};

export default Signup;
